import { VStack } from "@@panda/jsx";
import { formatIsoDate } from "@internal/utils/dates";
import { Typography } from "components/atoms/Typography";
import { Form } from "components/forms/Form/Form";
import { DeclarationCard } from "components/molecules/DeclarationCard";
import { Styled } from "components/styled";
import { StepWrap } from "screens/onboarding/components/StepWrap";

import { useDeclarationStep } from "./useDeclarationStep";

export function DeclarationStep() {
  const { handleSubmit, isSubmitting, account } = useDeclarationStep();
  const { first_name = "", last_name = "" } = account ?? {};

  return (
    <StepWrap>
      <VStack gap="m" mb="m" w="full">
        <Typography as="h2" variant="heading.2" textAlign="center" width="full">
          Final declaration
        </Typography>

        <DeclarationCard />

        <Form mt="m" w="full" onSubmit={handleSubmit}>
          <Form.Signature
            firstName={first_name}
            lastName={last_name}
            name="signature"
            label="Signature*"
          />

          <Styled.p pt="l" fontWeight={300}>
            Signed on{" "}
            {formatIsoDate(new Date().toISOString(), { dateStyle: "short" })}
          </Styled.p>

          <Form.Submit isLoading={isSubmitting} w="full" mt="1.25rem">
            Agree & continue
          </Form.Submit>
        </Form>
      </VStack>
    </StepWrap>
  );
}
