/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { fetcher } from "../../client/client";
import type {
  DealDraftCreateBody,
  DealsUpdateDraftArchiveStatusBody,
  DealsUpdateDraftGetLogoResponse,
  DealsUpdateDraftReviewStatusBody,
  DealsUpdateDraftUploadLogoBody,
  DraftCreateOkResponse,
  DraftGetOkResponse,
  DraftMovedResponse,
  DraftUpdateBody,
  InternalServerErrorResponse,
  NotAuthorisedResponse,
  NotFoundResponse,
} from "../schemas";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Create a deal draft with initial pricing plan and currency.
 */
export const dealsCreateDraft = (
  dealDraftCreateBody: DealDraftCreateBody,
  signal?: AbortSignal
) => {
  return fetcher<DraftCreateOkResponse>({
    url: `/v3/deals/drafts`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: dealDraftCreateBody,
    signal,
  });
};

export const getDealsCreateDraftMutationOptions = <
  TError = NotAuthorisedResponse | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsCreateDraft>>,
    TError,
    { data: DealDraftCreateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsCreateDraft>>,
  TError,
  { data: DealDraftCreateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsCreateDraft>>,
    { data: DealDraftCreateBody }
  > = (props) => {
    const { data } = props ?? {};

    return dealsCreateDraft(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsCreateDraftMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsCreateDraft>>
>;
export type DealsCreateDraftMutationBody = DealDraftCreateBody;
export type DealsCreateDraftMutationError =
  | NotAuthorisedResponse
  | InternalServerErrorResponse;

export const useDealsCreateDraft = <
  TError = NotAuthorisedResponse | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsCreateDraft>>,
    TError,
    { data: DealDraftCreateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsCreateDraft>>,
  TError,
  { data: DealDraftCreateBody },
  TContext
> => {
  const mutationOptions = getDealsCreateDraftMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a draft.
 */
export const dealsGetDraft = (dealDraftId: string, signal?: AbortSignal) => {
  return fetcher<DraftGetOkResponse>({
    url: `/v3/deals/drafts/${dealDraftId}`,
    method: "GET",
    signal,
  });
};

export const getDealsGetDraftQueryKey = (dealDraftId: string) => {
  return [`/v3/deals/drafts/${dealDraftId}`] as const;
};

export const getDealsGetDraftQueryOptions = <
  TData = Awaited<ReturnType<typeof dealsGetDraft>>,
  TError =
    | DraftMovedResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetDraft>>, TError, TData>
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDealsGetDraftQueryKey(dealDraftId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dealsGetDraft>>> = ({
    signal,
  }) => dealsGetDraft(dealDraftId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dealDraftId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof dealsGetDraft>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DealsGetDraftQueryResult = NonNullable<
  Awaited<ReturnType<typeof dealsGetDraft>>
>;
export type DealsGetDraftQueryError =
  | DraftMovedResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useDealsGetDraft<
  TData = Awaited<ReturnType<typeof dealsGetDraft>>,
  TError =
    | DraftMovedResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetDraft>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetDraft>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDealsGetDraft<
  TData = Awaited<ReturnType<typeof dealsGetDraft>>,
  TError =
    | DraftMovedResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetDraft>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetDraft>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDealsGetDraft<
  TData = Awaited<ReturnType<typeof dealsGetDraft>>,
  TError =
    | DraftMovedResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetDraft>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useDealsGetDraft<
  TData = Awaited<ReturnType<typeof dealsGetDraft>>,
  TError =
    | DraftMovedResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof dealsGetDraft>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDealsGetDraftQueryOptions(dealDraftId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update a draft.
 */
export const dealsUpdateDraft = (
  dealDraftId: string,
  draftUpdateBody: DraftUpdateBody
) => {
  return fetcher<DraftGetOkResponse>({
    url: `/v3/deals/drafts/${dealDraftId}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: draftUpdateBody,
  });
};

export const getDealsUpdateDraftMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUpdateDraft>>,
    TError,
    { dealDraftId: string; data: DraftUpdateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsUpdateDraft>>,
  TError,
  { dealDraftId: string; data: DraftUpdateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsUpdateDraft>>,
    { dealDraftId: string; data: DraftUpdateBody }
  > = (props) => {
    const { dealDraftId, data } = props ?? {};

    return dealsUpdateDraft(dealDraftId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsUpdateDraftMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsUpdateDraft>>
>;
export type DealsUpdateDraftMutationBody = DraftUpdateBody;
export type DealsUpdateDraftMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useDealsUpdateDraft = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUpdateDraft>>,
    TError,
    { dealDraftId: string; data: DraftUpdateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsUpdateDraft>>,
  TError,
  { dealDraftId: string; data: DraftUpdateBody },
  TContext
> => {
  const mutationOptions = getDealsUpdateDraftMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Archive an in-review draft and optionally publish it to a live deal.

 */
export const dealsUpdateDraftArchiveStatus = (
  dealDraftId: string,
  dealsUpdateDraftArchiveStatusBody: DealsUpdateDraftArchiveStatusBody
) => {
  return fetcher<void>({
    url: `/v3/deals/drafts/${dealDraftId}/archive-status`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: dealsUpdateDraftArchiveStatusBody,
  });
};

export const getDealsUpdateDraftArchiveStatusMutationOptions = <
  TError =
    | void
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUpdateDraftArchiveStatus>>,
    TError,
    { dealDraftId: string; data: DealsUpdateDraftArchiveStatusBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsUpdateDraftArchiveStatus>>,
  TError,
  { dealDraftId: string; data: DealsUpdateDraftArchiveStatusBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsUpdateDraftArchiveStatus>>,
    { dealDraftId: string; data: DealsUpdateDraftArchiveStatusBody }
  > = (props) => {
    const { dealDraftId, data } = props ?? {};

    return dealsUpdateDraftArchiveStatus(dealDraftId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsUpdateDraftArchiveStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsUpdateDraftArchiveStatus>>
>;
export type DealsUpdateDraftArchiveStatusMutationBody =
  DealsUpdateDraftArchiveStatusBody;
export type DealsUpdateDraftArchiveStatusMutationError =
  | void
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useDealsUpdateDraftArchiveStatus = <
  TError =
    | void
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUpdateDraftArchiveStatus>>,
    TError,
    { dealDraftId: string; data: DealsUpdateDraftArchiveStatusBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsUpdateDraftArchiveStatus>>,
  TError,
  { dealDraftId: string; data: DealsUpdateDraftArchiveStatusBody },
  TContext
> => {
  const mutationOptions =
    getDealsUpdateDraftArchiveStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get the draft's logo.
 */
export const dealsGetDraftLogo = (
  dealDraftId: string,
  signal?: AbortSignal
) => {
  return fetcher<DealsUpdateDraftGetLogoResponse>({
    url: `/v3/deals/drafts/${dealDraftId}/logo`,
    method: "GET",
    signal,
  });
};

export const getDealsGetDraftLogoQueryKey = (dealDraftId: string) => {
  return [`/v3/deals/drafts/${dealDraftId}/logo`] as const;
};

export const getDealsGetDraftLogoQueryOptions = <
  TData = Awaited<ReturnType<typeof dealsGetDraftLogo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetDraftLogo>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDealsGetDraftLogoQueryKey(dealDraftId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dealsGetDraftLogo>>
  > = ({ signal }) => dealsGetDraftLogo(dealDraftId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!dealDraftId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof dealsGetDraftLogo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DealsGetDraftLogoQueryResult = NonNullable<
  Awaited<ReturnType<typeof dealsGetDraftLogo>>
>;
export type DealsGetDraftLogoQueryError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useDealsGetDraftLogo<
  TData = Awaited<ReturnType<typeof dealsGetDraftLogo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetDraftLogo>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetDraftLogo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDealsGetDraftLogo<
  TData = Awaited<ReturnType<typeof dealsGetDraftLogo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetDraftLogo>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof dealsGetDraftLogo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDealsGetDraftLogo<
  TData = Awaited<ReturnType<typeof dealsGetDraftLogo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetDraftLogo>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useDealsGetDraftLogo<
  TData = Awaited<ReturnType<typeof dealsGetDraftLogo>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  dealDraftId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof dealsGetDraftLogo>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDealsGetDraftLogoQueryOptions(dealDraftId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Upload an avatar for the specified deal draft. The image should be
square and if not, will be normalised to the following dimensions:
500x500 using the shortest edge and a centred crop strategy. Logos that
are below this size will be scaled up and logos above this size will be
scaled down. The caller should ensure these constraints are met first.

 */
export const dealsSetDraftLogo = (
  dealDraftId: string,
  dealsUpdateDraftUploadLogoBody: DealsUpdateDraftUploadLogoBody,
  signal?: AbortSignal
) => {
  return fetcher<void>({
    url: `/v3/deals/drafts/${dealDraftId}/logo`,
    method: "POST",
    headers: { "Content-Type": "application/octet-stream" },
    data: dealsUpdateDraftUploadLogoBody,
    signal,
  });
};

export const getDealsSetDraftLogoMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsSetDraftLogo>>,
    TError,
    { dealDraftId: string; data: DealsUpdateDraftUploadLogoBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsSetDraftLogo>>,
  TError,
  { dealDraftId: string; data: DealsUpdateDraftUploadLogoBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsSetDraftLogo>>,
    { dealDraftId: string; data: DealsUpdateDraftUploadLogoBody }
  > = (props) => {
    const { dealDraftId, data } = props ?? {};

    return dealsSetDraftLogo(dealDraftId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsSetDraftLogoMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsSetDraftLogo>>
>;
export type DealsSetDraftLogoMutationBody = DealsUpdateDraftUploadLogoBody;
export type DealsSetDraftLogoMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useDealsSetDraftLogo = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsSetDraftLogo>>,
    TError,
    { dealDraftId: string; data: DealsUpdateDraftUploadLogoBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsSetDraftLogo>>,
  TError,
  { dealDraftId: string; data: DealsUpdateDraftUploadLogoBody },
  TContext
> => {
  const mutationOptions = getDealsSetDraftLogoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Submit the draft for review.
 */
export const dealsUpdateDraftReviewStatus = (
  dealDraftId: string,
  dealsUpdateDraftReviewStatusBody: DealsUpdateDraftReviewStatusBody
) => {
  return fetcher<void>({
    url: `/v3/deals/drafts/${dealDraftId}/review-status`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: dealsUpdateDraftReviewStatusBody,
  });
};

export const getDealsUpdateDraftReviewStatusMutationOptions = <
  TError =
    | void
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUpdateDraftReviewStatus>>,
    TError,
    { dealDraftId: string; data: DealsUpdateDraftReviewStatusBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof dealsUpdateDraftReviewStatus>>,
  TError,
  { dealDraftId: string; data: DealsUpdateDraftReviewStatusBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof dealsUpdateDraftReviewStatus>>,
    { dealDraftId: string; data: DealsUpdateDraftReviewStatusBody }
  > = (props) => {
    const { dealDraftId, data } = props ?? {};

    return dealsUpdateDraftReviewStatus(dealDraftId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DealsUpdateDraftReviewStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof dealsUpdateDraftReviewStatus>>
>;
export type DealsUpdateDraftReviewStatusMutationBody =
  DealsUpdateDraftReviewStatusBody;
export type DealsUpdateDraftReviewStatusMutationError =
  | void
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useDealsUpdateDraftReviewStatus = <
  TError =
    | void
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof dealsUpdateDraftReviewStatus>>,
    TError,
    { dealDraftId: string; data: DealsUpdateDraftReviewStatusBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof dealsUpdateDraftReviewStatus>>,
  TError,
  { dealDraftId: string; data: DealsUpdateDraftReviewStatusBody },
  TContext
> => {
  const mutationOptions =
    getDealsUpdateDraftReviewStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
