import { Typography } from "components/atoms/Typography";

interface Props {
  name: string;
}

export function LeadCell({ name }: Props) {
  return (
    <>
      <Typography
        variant="body"
        as="span"
        color="grey.gunsmoke"
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderColor="transparent"
        transition="border .1s ease-in-out"
      >
        {name}
      </Typography>
    </>
  );
}
