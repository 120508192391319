import { Box, Flex, HStack, styled } from "@@panda/jsx";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  SyndicateLeadReference,
  SyndicateLeadReferenceList,
} from "@internal/rest/generated/schemas";
import { Button } from "components/atoms/Button";
import { TextOverflow } from "components/atoms/TextOverflow";
import { Dialog } from "components/molecules/Dialog";

import { Members, useAdditionalMembers } from "./useAdditionalMembers";
import { computeLeadName } from "./utils";

interface Props {
  members: SyndicateLeadReferenceList;
  onResetMembers: (members: Members) => void;
  onRemoveMember: (member: SyndicateLeadReference) => void;
  syndicateId: string;
}

export function AdditionalMembers({
  members,
  onResetMembers,
  onRemoveMember,
  syndicateId,
}: Props) {
  const { isSyndicateEditable, isLoading } = useAdditionalMembers({
    currentMembers: members,
    onResetMembers,
    syndicateId,
  });

  return isSyndicateEditable && !isLoading ? (
    <Box mt="6" data-testid="synd-leads-section">
      <styled.p fontWeight={500} color="grey.iron" mb="0">
        Additional syndicate leads
      </styled.p>

      <styled.p fontSize="xs" fontWeight="normal" color="grey.gunsmoke" mb="2">
        Add other users that need access to this syndicate.
      </styled.p>
      {members.map((member) => {
        const withName = member.first_name && member.last_name;

        const name = withName
          ? `${member.first_name} ${member.last_name}`
          : (member.email as string);

        const formattedName = computeLeadName(name);

        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="full"
            maxW="18rem"
            mb="3"
            key={member.email}
          >
            <TextOverflow width="full" maxWidth="15rem">
              {formattedName}
            </TextOverflow>

            <button
              aria-label={`Remove ${formattedName}`}
              onClick={() => onRemoveMember(member)}
            >
              <TrashIcon color="#F98B85" width="20px" height="20px" />
            </button>
          </Flex>
        );
      })}
      <Dialog.Trigger>
        <Button variant="secondary" data-testid="add-lead-button" mt="1">
          <HStack>
            <PlusCircleIcon height="18px" width="18px" color="white" />
            <p> Add another syndicate lead</p>
          </HStack>
        </Button>
      </Dialog.Trigger>
    </Box>
  ) : null;
}
