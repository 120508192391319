import { HTMLStyledProps } from "@@panda/jsx";
import { Card } from "components/molecules/Card";

export function Content({ children, ...rest }: HTMLStyledProps<"div">) {
  return (
    <Card
      bgColor="grey.bunker"
      p={["m", "m", "l"]}
      w="full"
      maxW="30rem"
      withBorder
      withShadow
      {...rest}
    >
      {children}
    </Card>
  );
}
