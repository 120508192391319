import { token } from "@@panda/tokens";
import { Typography } from "components/atoms/Typography";
import { PropsWithChildren } from "react";

export function FloatingPrefix({ children }: PropsWithChildren) {
  return (
    <Typography
      variant="body"
      position="absolute"
      color="grey.gunsmoke"
      bgColor="grey.woodsmoke"
      as="span"
      display="flex"
      alignItems="center"
      data-testid="amount-field-symbol"
      zIndex="floating"
      border="none"
      pl="0.5rem"
      pr="1rem"
      py="0"
      left="3px"
      top="2.5px"
      lineHeight="tight"
      style={{
        borderRightWidth: "1px",
        borderRightStyle: "solid",
        borderColor: token("colors.grey.dune"),
        height: "37px",
      }}
    >
      {children}
    </Typography>
  );
}
