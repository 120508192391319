import { VStack, styled } from "@@panda/jsx";
import { DataRow } from "components/atoms/DataRow";
import { StatusBanner } from "components/molecules/StatusBanner";
import { isBrandedPricePlan } from "utility/pricing";

import { useSyndicateSection } from "./useSyndicateSection";

export function SyndicateSection() {
  const { ready, error, data } = useSyndicateSection();

  if (!ready) return <StatusBanner error={error} />;

  return (
    <>
      <DataRow label="Syndicate Name" value={data.name} />

      {isBrandedPricePlan(data.pricePlan) ? (
        <DataRow label="Entity Legal Name" value={data.legalName} />
      ) : null}

      {data.additionalLeads?.length ? (
        <DataRow
          label="Additional syndicate leads"
          value={
            <VStack alignItems="flex-end">
              {data.additionalLeads.map((lead) => (
                <styled.p key={lead.email}>
                  {lead.first_name
                    ? `${lead.first_name} ${lead.last_name}`
                    : lead.email}
                </styled.p>
              ))}
            </VStack>
          }
        />
      ) : null}
    </>
  );
}
