import { cx } from "@@panda/css";
import * as RadixTabs from "@radix-ui/react-tabs";
import { useState } from "react";
import { useQueryState } from "utility/hooks/useQueryState";

import { Tab } from "./components/Tab";
import { TabList } from "./components/TabList";
import { TabPanel } from "./components/TabPanel";
import { TabPanels } from "./components/TabPanels";
import { TabsContext } from "./context";
import { TabsInternalContext } from "./internalContext";
import { Variants, tabsRecipe } from "./styles";

Tabs.TabList = TabList;
Tabs.Tab = Tab;
Tabs.Panels = TabPanels;
Tabs.Panel = TabPanel;

type Props<TDefaultValue extends string> = Omit<
  RadixTabs.TabsProps,
  "defaultValue" | "variant"
> & { defaultValue: TDefaultValue; variant?: Variants; useUrlState?: boolean };

export function Tabs<TDefaultValue extends string>({
  children,
  variant,
  className,
  useUrlState = true,
  ...rest
}: Props<TDefaultValue>) {
  const classes = tabsRecipe({ visual: variant });
  const [internalState, setInternalState] = useState<string>(rest.defaultValue);
  const [urlState, setUrlState] = useQueryState("tab", {
    fallback: rest.defaultValue,
  });

  const value = useUrlState ? urlState : internalState;
  const handleValueChange = useUrlState ? setUrlState : setInternalState;

  return (
    <RadixTabs.Root
      className={cx(classes.root, className)}
      value={value}
      onValueChange={handleValueChange}
      {...rest}
    >
      <TabsContext.Provider value={{ value }}>
        <TabsInternalContext.Provider value={{ classes }}>
          {children}
        </TabsInternalContext.Provider>
      </TabsContext.Provider>
    </RadixTabs.Root>
  );
}
