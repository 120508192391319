import { css } from "@@panda/css";
import { ComponentProps } from "@@panda/types";
import { Typography } from "components/atoms/Typography";
import { HTMLAttributes } from "react";

export function FormError({
  children,
  ...rest
}: Omit<ComponentProps<typeof Typography>, "variant"> &
  HTMLAttributes<HTMLParagraphElement>) {
  return (
    <Typography
      variant="subtext"
      data-form-error
      className={css({
        mt: "xs",
        fontWeight: "500",
        color: "yellow.vesuvius",
        position: "absolute",
      })}
      {...rest}
    >
      {children}
    </Typography>
  );
}
