import { cx } from "@@panda/css";
import { HStack } from "@@panda/jsx";
import { DropdownMenuItemProps, Item } from "@radix-ui/react-dropdown-menu";
import { Typography } from "components/atoms/Typography";
import NextLink from "next/link";
import { ReactNode } from "react";

import { useMenuContext } from "../context";

interface Props extends DropdownMenuItemProps {
  label: string;
  variant: "button" | "link";
  icon?: ReactNode;
  href?: string;
}

export function MenuItem({
  label,
  icon,
  variant,
  href = "",
  className,
  ...rest
}: Props) {
  const { styles } = useMenuContext();
  const isLink = variant === "link";

  const content = (
    <HStack justifyContent="flex-start" alignItems="center">
      {icon}
      <Typography variant="body" as="span">
        {label}
      </Typography>
    </HStack>
  );

  return (
    <Item asChild={isLink} className={cx(styles.item, className)} {...rest}>
      {isLink ? <NextLink href={href}>{content}</NextLink> : content}
    </Item>
  );
}
