/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type AccountAppropriatenessAssessmentAppropriatenessAssessmentStatus =
  (typeof AccountAppropriatenessAssessmentAppropriatenessAssessmentStatus)[keyof typeof AccountAppropriatenessAssessmentAppropriatenessAssessmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountAppropriatenessAssessmentAppropriatenessAssessmentStatus = {
  not_started: "not_started",
  completed_failed: "completed_failed",
  completed_passed: "completed_passed",
  blocked: "blocked",
} as const;
