import { cx } from "@@panda/css";
import { HTMLStyledProps } from "@@panda/types";
import { Styled } from "components/styled";

import { useTabsInternalContext } from "../internalContext";

export function TabPanels({ className, ...p }: HTMLStyledProps<"div">) {
  const { classes } = useTabsInternalContext();
  return <Styled.div {...p} className={cx(classes.panels, className)} />;
}
