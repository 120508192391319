import { css } from "@@panda/css";
import { HStack, VStack } from "@@panda/jsx";
import { Thumbnail } from "components/atoms/Thumbnail";
import { Typography } from "components/atoms/Typography";

interface Props {
  name: string;
  type: string;
  logo: string;
}

export function CompanyCell({ name, type, logo }: Props) {
  return (
    <HStack
      gap="m"
      justifyContent="flex-start"
      alignItems="center"
      w="full"
      cursor="pointer"
    >
      <Thumbnail
        alt={name}
        src={logo}
        width="2.5rem"
        height="2.5rem"
        className={css({ flexShrink: "0" })}
        placeholder="deal"
        circle
      />

      <VStack alignItems="flex-start" gap="xs" w="full">
        <Typography
          variant="body"
          lineClamp={1}
          width="auto"
          textOverflow="ellipsis"
          as="span"
          lineHeight="tight"
          transition="border .1s ease-in-out"
          /* Important: for the hover state of the whole deal row to work! See hover state above */
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="transparent"
          data-company-name="true"
        >
          {name}
        </Typography>
        <Typography variant="body" color="grey.gunsmoke" lineHeight="tight">
          {type}
        </Typography>
      </VStack>
    </HStack>
  );
}
