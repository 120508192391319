import { Button, ButtonProps } from "components/atoms/Button";
import { HTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";

type Props = ButtonProps &
  HTMLAttributes<HTMLButtonElement> & {
    isDisabled?: boolean;
    "data-testid"?: string;
  };

export function FormSubmit({
  children,
  isDisabled = false,
  isLoading = false,
  ...rest
}: Props) {
  const {
    formState: { errors, isSubmitting },
  } = useFormContext();

  const withErrors = Object.keys(errors).length > 0;

  return (
    <Button
      type="submit"
      isLoading={isLoading || isSubmitting}
      isDisabled={isDisabled || withErrors}
      {...rest}
    >
      {children}
    </Button>
  );
}
