/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The status of a deal draft, which indicates which stage of the pipeline
it's in. While "drafting", the author and admins may modify the draft.
Once the draft status is changed to "review", the draft cannot be edited
by the owner, only by Odin admins. Once the details have been ironed out
an Odin admin can change the status to "archive" and optionally publish
a newly created live deal from the information held in the draft.

 */
export type DraftStatus = (typeof DraftStatus)[keyof typeof DraftStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DraftStatus = {
  draft: "draft",
  review: "review",
  archive: "archive",
} as const;
