import { VStack } from "@@panda/jsx";
import { Typography } from "components/atoms/Typography";
import { Card } from "components/molecules/Card";
import { Styled } from "components/styled";
import { PropsWithChildren } from "react";

export function DeclarationCard({ children }: PropsWithChildren) {
  return (
    <Card
      width="full"
      textAlign="left"
      py="l"
      px="m"
      data-testid="self-certified-card"
      withBorder
    >
      <VStack alignItems="flex-start" gap="s">
        <Typography
          variant="heading.3"
          as="h3"
          lineHeight="1.6"
          whiteSpace="normal"
          color="grey.iron"
          fontWeight="semibold"
          fontSize="1.1rem"
          mb="m"
        >
          I declare that I wish to be treated as a self-certified sophisticated
          investor
        </Typography>

        <Typography
          as="p"
          variant="body"
          lineHeight="tight"
          pt="s"
          color="grey.iron"
        >
          I understand that this means:
        </Typography>

        <Styled.ul
          listStyle="inside"
          display="flex"
          flexDir="column"
          gap="l"
          py="m"
          listStylePosition="outside"
          pl="0.8125rem"
        >
          <Styled.li color="grey.iron">
            I can receive financial promotions where the contents may not comply
            with the rules made by the Financial Conduct Authority (FCA);
          </Styled.li>

          <Styled.li color="grey.iron">
            I can expect
            <Bold> no protection </Bold>
            from the FCA, the Financial Ombundsman Service or the Financial
            Services Compensation Scheme;
          </Styled.li>

          <Styled.li color="grey.iron">
            I am aware that it is open to me to seek advice from someone who
            specialises in advising on investments and;
          </Styled.li>

          <Styled.li color="grey.iron">
            <Bold>I accept that I could lose all of the money I invest.</Bold>
          </Styled.li>
        </Styled.ul>
        {children}
      </VStack>
    </Card>
  );
}

function Bold(props: PropsWithChildren) {
  return (
    <Typography variant="body" as="span" fontWeight="bold" color="white">
      {props.children}
    </Typography>
  );
}
