import { HStack, HTMLStyledProps, VStack } from "@@panda/jsx";
import { Typography } from "components/atoms/Typography";
import { Card } from "components/molecules/Card";

interface Props extends HTMLStyledProps<"div"> {
  title: string;
  icon: React.ReactNode;
  isSelected: boolean;
}

export function IntentionCard({
  title,
  icon,
  isSelected,
  children,
  ...rest
}: Props) {
  return (
    <Card
      px="4"
      py="4"
      style={{ height: "6.15rem" }}
      cursor="pointer"
      role="button"
      withBorder
      withHover
      borderRadius="md"
      borderColor={
        isSelected ? "brand.krilin !important" : "grey.dune !important"
      }
      {...rest}
    >
      <VStack alignItems="flex-start" justifyContent="center" h="full">
        <HStack
          width="auto"
          justifyContent="space-between"
          gap={["s", "s", "m", "l"]}
          alignItems="center"
        >
          <HStack
            bgColor={isSelected ? "brand.krilin" : "grey.dune"}
            borderRadius="full"
            minW="10"
            h="10"
            alignItems="center"
            justifyContent="center"
            width="auto"
          >
            {icon}
          </HStack>
          <VStack alignItems="flex-start" textAlign="start" gap="0">
            <Typography variant="heading.4" as="span" fontWeight={600}>
              {title}
            </Typography>

            <Typography variant="body" color="grey.gunsmoke">
              {children}
            </Typography>
          </VStack>
        </HStack>
      </VStack>
    </Card>
  );
}
