import {
  getAccountsGetSelfQueryKey,
  useAccountsGetSelf,
  useAccountsUpdateSelf,
  useAccountsUploadImage,
} from "@internal/rest/generated/queries/accounts";
import { useQueryClient } from "@tanstack/react-query";
import { ImgArgs } from "components/molecules/AvatarEditor";
import { useState } from "react";
import { useAuth } from "services/auth/AuthProvider";
import { toast } from "services/toast";
import { handleResourceEffect } from "utility/async";

import { FormValues } from "./InvestorDetailsStep";

export function useInvestorDetailsStep() {
  const queryClient = useQueryClient();
  const accountResult = useAccountsGetSelf();

  const { account } = useAuth();
  const { mutateAsync: updateAccount } = useAccountsUpdateSelf();
  const accountId = accountResult.data?.id ?? "";
  const { mutateAsync: uploadImage } = useAccountsUploadImage();
  const [avatar, setAvatar] = useState<null | string>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const accountImgUrl = accountId ? (account?.logo_url ?? "") : "";

  const [isUsernameLoading, setIsUsernameLoading] = useState(false);

  async function onSubmit(values: FormValues) {
    setSubmitting(true);

    await handleResourceEffect({
      async action() {
        await updateAccount({
          data: {
            first_name: values.firstName,
            last_name: values.lastName,
          },
        });

        await queryClient.invalidateQueries({
          queryKey: accountResult.queryKey,
        });
      },
      cleanup() {
        setSubmitting(false);
      },
      error: {
        title: "Onboarding error",
      },
    });
  }

  async function onSaveAvatar({ img, url }: ImgArgs) {
    try {
      await uploadImage({ data: img });
      setAvatar(url);
      await queryClient.invalidateQueries({
        queryKey: getAccountsGetSelfQueryKey(),
      });
    } catch (e: unknown) {
      const err = e as Error;
      toast.show({
        status: "error",
        title: "An unexpected draft problem ocurred",
        desc: `Something went wrong while uploading your profile picture. In case the error persists contact us at hello@joinodin.com`,
      });
      // bubble-up the error so the Avatar Editor is aware of it...
      throw err;
    }
  }

  return {
    ready: accountResult.isSuccess,
    data: {
      firstName: accountResult.data?.first_name || "",
      lastName: accountResult.data?.last_name || "",
      username: accountResult.data?.handle || "",
    },
    avatar: avatar || accountImgUrl,
    isSubmitting,
    onSubmit,
    onSaveAvatar,
    isUsernameLoading,
    setIsUsernameLoading,
  };
}
