/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Discriminant of a portfolio item to let this be seen by other users
 */
export type PublicProfilePortfolioItemVisibility =
  (typeof PublicProfilePortfolioItemVisibility)[keyof typeof PublicProfilePortfolioItemVisibility];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublicProfilePortfolioItemVisibility = {
  public: "public",
  private: "private",
} as const;
