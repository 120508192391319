import { VStack } from "@@panda/jsx";
import { SystemStyleObject } from "@@panda/types";
import { MergeProps } from "@internal/utils/types";
import {
  LAYOUT_MAX_WIDTH,
  WIDE_LAYOUT_MAX_WIDTH,
  getLayoutProps,
} from "components/templates/Layout/utils";
import { ComponentProps } from "react";

interface CustomProps {
  variant?: "wide" | "narrow";
  children?: React.ReactNode;
  style?: React.CSSProperties;
  css?: SystemStyleObject;
}

type Props = MergeProps<ComponentProps<typeof VStack>, CustomProps>;

export function LayoutWrap({
  children,
  variant = "wide",
  css,
  style,
  ...rest
}: Props) {
  const layoutWidth =
    variant === "wide" ? WIDE_LAYOUT_MAX_WIDTH : LAYOUT_MAX_WIDTH;

  return (
    <VStack
      {...getLayoutProps({ width: layoutWidth, css })}
      style={{ ...style, maxWidth: layoutWidth }}
      {...rest}
    >
      {children}
    </VStack>
  );
}
