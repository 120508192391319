/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { fetcher } from "../../client/client";
import type {
  AccountsGetImageSuccessResponse,
  AccountsUploadImageBody,
  BadRequestResponse,
  CommunitiesCreateBody,
  CommunitiesCreateSuccessResponse,
  CommunitiesGetSuccessResponse,
  CommunitiesListSuccessResponse,
  CommunitiesMembersListParams,
  CommunitiesMembersListSuccessResponse,
  CommunitiesUpdateBody,
  CompanyPortfolioItemBodyBody,
  CompanyPortfolioItemEditableBodyBody,
  ConflictResponse,
  DirectAccessErrorResponse,
  InternalServerErrorResponse,
  NotAuthorisedResponse,
  NotFoundResponse,
} from "../schemas";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * List all communities where the logged in user is a member or lead.

 */
export const communitiesList = (signal?: AbortSignal) => {
  return fetcher<CommunitiesListSuccessResponse>({
    url: `/v3/communities`,
    method: "GET",
    signal,
  });
};

export const getCommunitiesListQueryKey = () => {
  return [`/v3/communities`] as const;
};

export const getCommunitiesListQueryOptions = <
  TData = Awaited<ReturnType<typeof communitiesList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof communitiesList>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCommunitiesListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof communitiesList>>> = ({
    signal,
  }) => communitiesList(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof communitiesList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type CommunitiesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof communitiesList>>
>;
export type CommunitiesListQueryError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | InternalServerErrorResponse;

export function useCommunitiesList<
  TData = Awaited<ReturnType<typeof communitiesList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | InternalServerErrorResponse,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof communitiesList>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof communitiesList>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useCommunitiesList<
  TData = Awaited<ReturnType<typeof communitiesList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof communitiesList>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof communitiesList>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useCommunitiesList<
  TData = Awaited<ReturnType<typeof communitiesList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof communitiesList>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useCommunitiesList<
  TData = Awaited<ReturnType<typeof communitiesList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof communitiesList>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getCommunitiesListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Create a new community with the requesting account as the only lead.

 */
export const communitiesCreate = (
  communitiesCreateBody: CommunitiesCreateBody,
  signal?: AbortSignal
) => {
  return fetcher<CommunitiesCreateSuccessResponse>({
    url: `/v3/communities`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: communitiesCreateBody,
    signal,
  });
};

export const getCommunitiesCreateMutationOptions = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | ConflictResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesCreate>>,
    TError,
    { data: CommunitiesCreateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof communitiesCreate>>,
  TError,
  { data: CommunitiesCreateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof communitiesCreate>>,
    { data: CommunitiesCreateBody }
  > = (props) => {
    const { data } = props ?? {};

    return communitiesCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CommunitiesCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof communitiesCreate>>
>;
export type CommunitiesCreateMutationBody = CommunitiesCreateBody;
export type CommunitiesCreateMutationError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | ConflictResponse
  | InternalServerErrorResponse;

export const useCommunitiesCreate = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | ConflictResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesCreate>>,
    TError,
    { data: CommunitiesCreateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof communitiesCreate>>,
  TError,
  { data: CommunitiesCreateBody },
  TContext
> => {
  const mutationOptions = getCommunitiesCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Accept a community invite.
 */
export const communitiesAcceptInvite = (
  communityInviteCode: string,
  signal?: AbortSignal
) => {
  return fetcher<void>({
    url: `/v3/communities/invites/${communityInviteCode}`,
    method: "POST",
    signal,
  });
};

export const getCommunitiesAcceptInviteMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesAcceptInvite>>,
    TError,
    { communityInviteCode: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof communitiesAcceptInvite>>,
  TError,
  { communityInviteCode: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof communitiesAcceptInvite>>,
    { communityInviteCode: string }
  > = (props) => {
    const { communityInviteCode } = props ?? {};

    return communitiesAcceptInvite(communityInviteCode);
  };

  return { mutationFn, ...mutationOptions };
};

export type CommunitiesAcceptInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof communitiesAcceptInvite>>
>;

export type CommunitiesAcceptInviteMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useCommunitiesAcceptInvite = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesAcceptInvite>>,
    TError,
    { communityInviteCode: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof communitiesAcceptInvite>>,
  TError,
  { communityInviteCode: string },
  TContext
> => {
  const mutationOptions = getCommunitiesAcceptInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a community.
 */
export const communitiesGet = (
  communityHandle: string,
  signal?: AbortSignal
) => {
  return fetcher<CommunitiesGetSuccessResponse>({
    url: `/v3/communities/${communityHandle}`,
    method: "GET",
    signal,
  });
};

export const getCommunitiesGetQueryKey = (communityHandle: string) => {
  return [`/v3/communities/${communityHandle}`] as const;
};

export const getCommunitiesGetQueryOptions = <
  TData = Awaited<ReturnType<typeof communitiesGet>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof communitiesGet>>, TError, TData>
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCommunitiesGetQueryKey(communityHandle);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof communitiesGet>>> = ({
    signal,
  }) => communitiesGet(communityHandle, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!communityHandle,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof communitiesGet>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type CommunitiesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof communitiesGet>>
>;
export type CommunitiesGetQueryError =
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useCommunitiesGet<
  TData = Awaited<ReturnType<typeof communitiesGet>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof communitiesGet>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof communitiesGet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useCommunitiesGet<
  TData = Awaited<ReturnType<typeof communitiesGet>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof communitiesGet>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof communitiesGet>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useCommunitiesGet<
  TData = Awaited<ReturnType<typeof communitiesGet>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof communitiesGet>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useCommunitiesGet<
  TData = Awaited<ReturnType<typeof communitiesGet>>,
  TError = NotFoundResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof communitiesGet>>, TError, TData>
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getCommunitiesGetQueryOptions(communityHandle, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update a community.
 */
export const communitiesUpdate = (
  communityHandle: string,
  communitiesUpdateBody: CommunitiesUpdateBody
) => {
  return fetcher<CommunitiesGetSuccessResponse>({
    url: `/v3/communities/${communityHandle}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: communitiesUpdateBody,
  });
};

export const getCommunitiesUpdateMutationOptions = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesUpdate>>,
    TError,
    { communityHandle: string; data: CommunitiesUpdateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof communitiesUpdate>>,
  TError,
  { communityHandle: string; data: CommunitiesUpdateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof communitiesUpdate>>,
    { communityHandle: string; data: CommunitiesUpdateBody }
  > = (props) => {
    const { communityHandle, data } = props ?? {};

    return communitiesUpdate(communityHandle, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CommunitiesUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof communitiesUpdate>>
>;
export type CommunitiesUpdateMutationBody = CommunitiesUpdateBody;
export type CommunitiesUpdateMutationError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useCommunitiesUpdate = <
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesUpdate>>,
    TError,
    { communityHandle: string; data: CommunitiesUpdateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof communitiesUpdate>>,
  TError,
  { communityHandle: string; data: CommunitiesUpdateBody },
  TContext
> => {
  const mutationOptions = getCommunitiesUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Check availability of given handle
 */
export const communitiesHandleCheck = (
  communityHandle: string,
  signal?: AbortSignal
) => {
  return fetcher<void>({
    url: `/v3/communities/${communityHandle}/availability`,
    method: "GET",
    signal,
  });
};

export const getCommunitiesHandleCheckQueryKey = (communityHandle: string) => {
  return [`/v3/communities/${communityHandle}/availability`] as const;
};

export const getCommunitiesHandleCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof communitiesHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesHandleCheck>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCommunitiesHandleCheckQueryKey(communityHandle);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof communitiesHandleCheck>>
  > = ({ signal }) => communitiesHandleCheck(communityHandle, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!communityHandle,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof communitiesHandleCheck>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type CommunitiesHandleCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof communitiesHandleCheck>>
>;
export type CommunitiesHandleCheckQueryError =
  | BadRequestResponse
  | ConflictResponse
  | InternalServerErrorResponse;

export function useCommunitiesHandleCheck<
  TData = Awaited<ReturnType<typeof communitiesHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesHandleCheck>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof communitiesHandleCheck>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useCommunitiesHandleCheck<
  TData = Awaited<ReturnType<typeof communitiesHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesHandleCheck>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof communitiesHandleCheck>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useCommunitiesHandleCheck<
  TData = Awaited<ReturnType<typeof communitiesHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesHandleCheck>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useCommunitiesHandleCheck<
  TData = Awaited<ReturnType<typeof communitiesHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  communityHandle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesHandleCheck>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getCommunitiesHandleCheckQueryOptions(
    communityHandle,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Remove a company from a profile portfolio.
 */
export const communitiesRemoveCompany = (
  communityHandle: string,
  companyId: string
) => {
  return fetcher<CommunitiesGetSuccessResponse>({
    url: `/v3/communities/${communityHandle}/companies/${companyId}`,
    method: "DELETE",
  });
};

export const getCommunitiesRemoveCompanyMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesRemoveCompany>>,
    TError,
    { communityHandle: string; companyId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof communitiesRemoveCompany>>,
  TError,
  { communityHandle: string; companyId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof communitiesRemoveCompany>>,
    { communityHandle: string; companyId: string }
  > = (props) => {
    const { communityHandle, companyId } = props ?? {};

    return communitiesRemoveCompany(communityHandle, companyId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CommunitiesRemoveCompanyMutationResult = NonNullable<
  Awaited<ReturnType<typeof communitiesRemoveCompany>>
>;

export type CommunitiesRemoveCompanyMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useCommunitiesRemoveCompany = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesRemoveCompany>>,
    TError,
    { communityHandle: string; companyId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof communitiesRemoveCompany>>,
  TError,
  { communityHandle: string; companyId: string },
  TContext
> => {
  const mutationOptions = getCommunitiesRemoveCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Edit a community portfolio company
 */
export const communitiesEditCompany = (
  communityHandle: string,
  companyId: string,
  companyPortfolioItemEditableBodyBody: CompanyPortfolioItemEditableBodyBody
) => {
  return fetcher<CommunitiesGetSuccessResponse>({
    url: `/v3/communities/${communityHandle}/companies/${companyId}`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: companyPortfolioItemEditableBodyBody,
  });
};

export const getCommunitiesEditCompanyMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesEditCompany>>,
    TError,
    {
      communityHandle: string;
      companyId: string;
      data: CompanyPortfolioItemEditableBodyBody;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof communitiesEditCompany>>,
  TError,
  {
    communityHandle: string;
    companyId: string;
    data: CompanyPortfolioItemEditableBodyBody;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof communitiesEditCompany>>,
    {
      communityHandle: string;
      companyId: string;
      data: CompanyPortfolioItemEditableBodyBody;
    }
  > = (props) => {
    const { communityHandle, companyId, data } = props ?? {};

    return communitiesEditCompany(communityHandle, companyId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CommunitiesEditCompanyMutationResult = NonNullable<
  Awaited<ReturnType<typeof communitiesEditCompany>>
>;
export type CommunitiesEditCompanyMutationBody =
  CompanyPortfolioItemEditableBodyBody;
export type CommunitiesEditCompanyMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useCommunitiesEditCompany = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesEditCompany>>,
    TError,
    {
      communityHandle: string;
      companyId: string;
      data: CompanyPortfolioItemEditableBodyBody;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof communitiesEditCompany>>,
  TError,
  {
    communityHandle: string;
    companyId: string;
    data: CompanyPortfolioItemEditableBodyBody;
  },
  TContext
> => {
  const mutationOptions = getCommunitiesEditCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Add a company to a profile portfolio.
 */
export const communitiesAddCompany = (
  communityHandle: string,
  companyId: string,
  companyPortfolioItemBodyBody: CompanyPortfolioItemBodyBody
) => {
  return fetcher<CommunitiesGetSuccessResponse>({
    url: `/v3/communities/${communityHandle}/companies/${companyId}`,
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    data: companyPortfolioItemBodyBody,
  });
};

export const getCommunitiesAddCompanyMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesAddCompany>>,
    TError,
    {
      communityHandle: string;
      companyId: string;
      data: CompanyPortfolioItemBodyBody;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof communitiesAddCompany>>,
  TError,
  {
    communityHandle: string;
    companyId: string;
    data: CompanyPortfolioItemBodyBody;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof communitiesAddCompany>>,
    {
      communityHandle: string;
      companyId: string;
      data: CompanyPortfolioItemBodyBody;
    }
  > = (props) => {
    const { communityHandle, companyId, data } = props ?? {};

    return communitiesAddCompany(communityHandle, companyId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CommunitiesAddCompanyMutationResult = NonNullable<
  Awaited<ReturnType<typeof communitiesAddCompany>>
>;
export type CommunitiesAddCompanyMutationBody = CompanyPortfolioItemBodyBody;
export type CommunitiesAddCompanyMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useCommunitiesAddCompany = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesAddCompany>>,
    TError,
    {
      communityHandle: string;
      companyId: string;
      data: CompanyPortfolioItemBodyBody;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof communitiesAddCompany>>,
  TError,
  {
    communityHandle: string;
    companyId: string;
    data: CompanyPortfolioItemBodyBody;
  },
  TContext
> => {
  const mutationOptions = getCommunitiesAddCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a paginated list of members for the given community.
 */
export const communitiesMembersList = (
  communityHandle: string,
  params?: CommunitiesMembersListParams,
  signal?: AbortSignal
) => {
  return fetcher<CommunitiesMembersListSuccessResponse>({
    url: `/v3/communities/${communityHandle}/members`,
    method: "GET",
    params,
    signal,
  });
};

export const getCommunitiesMembersListQueryKey = (
  communityHandle: string,
  params?: CommunitiesMembersListParams
) => {
  return [
    `/v3/communities/${communityHandle}/members`,
    ...(params ? [params] : []),
  ] as const;
};

export const getCommunitiesMembersListQueryOptions = <
  TData = Awaited<ReturnType<typeof communitiesMembersList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  communityHandle: string,
  params?: CommunitiesMembersListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesMembersList>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCommunitiesMembersListQueryKey(communityHandle, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof communitiesMembersList>>
  > = ({ signal }) => communitiesMembersList(communityHandle, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!communityHandle,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof communitiesMembersList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type CommunitiesMembersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof communitiesMembersList>>
>;
export type CommunitiesMembersListQueryError =
  | BadRequestResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useCommunitiesMembersList<
  TData = Awaited<ReturnType<typeof communitiesMembersList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  communityHandle: string,
  params: undefined | CommunitiesMembersListParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesMembersList>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof communitiesMembersList>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useCommunitiesMembersList<
  TData = Awaited<ReturnType<typeof communitiesMembersList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  communityHandle: string,
  params?: CommunitiesMembersListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesMembersList>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof communitiesMembersList>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useCommunitiesMembersList<
  TData = Awaited<ReturnType<typeof communitiesMembersList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  communityHandle: string,
  params?: CommunitiesMembersListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesMembersList>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useCommunitiesMembersList<
  TData = Awaited<ReturnType<typeof communitiesMembersList>>,
  TError =
    | BadRequestResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  communityHandle: string,
  params?: CommunitiesMembersListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesMembersList>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getCommunitiesMembersListQueryOptions(
    communityHandle,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Returns the 500x500 PNG profile image for the account.
 */
export const communitiesGetLogo = (
  communityId: string,
  signal?: AbortSignal
) => {
  return fetcher<AccountsGetImageSuccessResponse>({
    url: `/v3/communities/${communityId}/logo`,
    method: "GET",
    signal,
  });
};

export const getCommunitiesGetLogoQueryKey = (communityId: string) => {
  return [`/v3/communities/${communityId}/logo`] as const;
};

export const getCommunitiesGetLogoQueryOptions = <
  TData = Awaited<ReturnType<typeof communitiesGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  communityId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesGetLogo>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCommunitiesGetLogoQueryKey(communityId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof communitiesGetLogo>>
  > = ({ signal }) => communitiesGetLogo(communityId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!communityId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof communitiesGetLogo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type CommunitiesGetLogoQueryResult = NonNullable<
  Awaited<ReturnType<typeof communitiesGetLogo>>
>;
export type CommunitiesGetLogoQueryError = DirectAccessErrorResponse;

export function useCommunitiesGetLogo<
  TData = Awaited<ReturnType<typeof communitiesGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  communityId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesGetLogo>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof communitiesGetLogo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useCommunitiesGetLogo<
  TData = Awaited<ReturnType<typeof communitiesGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  communityId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesGetLogo>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof communitiesGetLogo>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useCommunitiesGetLogo<
  TData = Awaited<ReturnType<typeof communitiesGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  communityId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesGetLogo>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useCommunitiesGetLogo<
  TData = Awaited<ReturnType<typeof communitiesGetLogo>>,
  TError = DirectAccessErrorResponse,
>(
  communityId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof communitiesGetLogo>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getCommunitiesGetLogoQueryOptions(communityId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Upload a profile image for the authenticated account.
 */
export const communitiesUploadLogo = (
  communityId: string,
  accountsUploadImageBody: AccountsUploadImageBody
) => {
  return fetcher<void>({
    url: `/v3/communities/${communityId}/logo`,
    method: "PUT",
    headers: { "Content-Type": "application/octet-stream" },
    data: accountsUploadImageBody,
  });
};

export const getCommunitiesUploadLogoMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesUploadLogo>>,
    TError,
    { communityId: string; data: AccountsUploadImageBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof communitiesUploadLogo>>,
  TError,
  { communityId: string; data: AccountsUploadImageBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof communitiesUploadLogo>>,
    { communityId: string; data: AccountsUploadImageBody }
  > = (props) => {
    const { communityId, data } = props ?? {};

    return communitiesUploadLogo(communityId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CommunitiesUploadLogoMutationResult = NonNullable<
  Awaited<ReturnType<typeof communitiesUploadLogo>>
>;
export type CommunitiesUploadLogoMutationBody = AccountsUploadImageBody;
export type CommunitiesUploadLogoMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useCommunitiesUploadLogo = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof communitiesUploadLogo>>,
    TError,
    { communityId: string; data: AccountsUploadImageBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof communitiesUploadLogo>>,
  TError,
  { communityId: string; data: AccountsUploadImageBody },
  TContext
> => {
  const mutationOptions = getCommunitiesUploadLogoMutationOptions(options);

  return useMutation(mutationOptions);
};
