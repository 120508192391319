import { VStack } from "@@panda/jsx";
import { DataRow } from "components/atoms/DataRow";
import { Typography } from "components/atoms/Typography";

import { useCarrySection } from "./useCarrySection";

export function CarrySection() {
  const section = useCarrySection();

  return (
    <>
      <DataRow label="Default carry" value={section.defaultCarry} />

      {section.recipients && section.recipients.length ? (
        <DataRow
          label="Carry recipients"
          value={
            <VStack alignItems="flex-end">
              {section.recipients.map((recipient) => (
                <Typography
                  variant="body"
                  key={`${recipient.name}-${recipient.split}`}
                >
                  {recipient.name} – {recipient.split}
                </Typography>
              ))}
            </VStack>
          }
        />
      ) : null}

      <DataRow
        label="Syndicate leads commitment"
        value={section.syndLeadsCommitment}
      />
    </>
  );
}
