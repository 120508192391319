import { LayoutProps } from "components/templates/Layout";
import { ReactNode } from "react";

type LayoutOptions = Omit<LayoutProps, "children">;

export const createPage = (
  page: (...args: unknown[]) => ReactNode,
  options?: LayoutOptions
) => {
  if (options) {
    Object.entries(options).forEach(([key, value]) => {
      if (!key || !value) return;

      //@ts-expect-error - hard to type
      page[key] = value;
    });
  }

  return page;
};
