import { HStack, VStack } from "@@panda/jsx";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { Button } from "components/atoms/Button";
import { Typography } from "components/atoms/Typography";
import { Dialog } from "components/molecules/Dialog";
import { StatusBanner } from "components/molecules/StatusBanner";
import { ComponentProps, ReactNode } from "react";

export interface FilesModalFile {
  label: string;
  url?: string | undefined | null;
  text?: string;
}

interface Props {
  files: FilesModalFile[];
  triggerSlot?: ReactNode;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  error?: ComponentProps<typeof StatusBanner>["error"];
  title?: string;
}

export function FilesModal({
  files,
  triggerSlot,
  isOpen,
  onClose,
  isLoading,
  error,
  title = "Documents",
}: Props) {
  return (
    <Dialog
      title={title}
      forceOpen={!!isOpen}
      onClose={onClose}
      styling={{ width: "30rem" }}
    >
      {triggerSlot && <Dialog.Trigger>{triggerSlot}</Dialog.Trigger>}
      <Dialog.Content>
        {isLoading || error ? (
          <StatusBanner error={error} />
        ) : (
          <VStack gap="0" w="full">
            {files
              .filter(({ url, text }) => Boolean(url || text))
              .map(({ label, url, text }) => (
                <HStack
                  key={url}
                  justifyContent="space-between"
                  alignItems="center"
                  w="full"
                  py="m"
                >
                  <Typography variant="body">{label}</Typography>
                  {url ? (
                    <Button
                      variant="secondary"
                      as="a"
                      href={url ?? ""}
                      target="_blank"
                      mt="s"
                      aria-label={`Download ${label}`}
                      testId={`${label} link`}
                      borderRadius="full"
                      px="0!"
                      w="2rem!"
                      h="2rem!"
                      m="0"
                    >
                      <ArrowDownTrayIcon color="white" width="1rem" />
                    </Button>
                  ) : (
                    <Typography variant="body">{text}</Typography>
                  )}
                </HStack>
              ))}
          </VStack>
        )}
      </Dialog.Content>
    </Dialog>
  );
}
