import { Tabs } from ".";
import { Tab } from "./components/Tab";
import { TabList } from "./components/TabList";
import { TabPanel } from "./components/TabPanel";
import { TabPanels } from "./components/TabPanels";

export function createTypedTabs<const TAllowedValues extends string>(
  _values?: readonly TAllowedValues[]
) {
  type Value = TAllowedValues extends []
    ? TAllowedValues[number]
    : TAllowedValues;

  return {
    Tabs: Tabs<Value>,
    TabList: TabList,
    Tab: Tab<Value>,
    Panels: TabPanels,
    Panel: TabPanel<Value>,
  };
}
