import { Box, Stack, styled } from "@@panda/jsx";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";
import { Link } from "components/atoms/Link";
import { Typography } from "components/atoms/Typography";
import { Alert } from "components/molecules/Alert";
import { PricePlanCard } from "components/molecules/PricePlanCard";
import { Styled } from "components/styled";
import { activeSyndicatePricePlans } from "utility/pricing";

import { CREATE_NEW_SYNDICATE_OPTION } from "../../utils";
import { usePricePlans } from "./usePricePlans";
import { mapPricePlanNameInDisclaimer } from "./utils";

interface Props {
  active: PricePlanName;
  syndicatePlan: PricePlanName;
  currency: BaseCurrency;
  onPricePlanChange: (pricePlan: PricePlanName) => void;
  currentSyndicateId: string;
  isLockedPlan: boolean;
  isPlanRequiringCsApproval: boolean;
}

export function PricePlans({
  active,
  onPricePlanChange,
  syndicatePlan,
  isLockedPlan,
  isPlanRequiringCsApproval,
  currentSyndicateId,
  currency,
}: Props) {
  const { onSelectPricePlan, isMdScreenUp } = usePricePlans({
    onPricePlanChange,
  });

  const isCreatingSyndicate =
    currentSyndicateId === CREATE_NEW_SYNDICATE_OPTION;

  const isLocked = isLockedPlan && !isCreatingSyndicate;

  return (
    <Box mb="2.5rem">
      <Box mt="xl" mb="0">
        <styled.p fontSize="sm" fontWeight={500} color="grey.iron" mb="m">
          Subscription tier
        </styled.p>
      </Box>
      {isLocked ? (
        <PricePlanCard
          onClick={onSelectPricePlan}
          pricePlan={syndicatePlan}
          selected={false}
          currency={currency}
          readonly
        />
      ) : (
        <Stack
          flexDir={isMdScreenUp ? "row" : "column"}
          gap="m"
          alignItems="flex-start"
        >
          {activeSyndicatePricePlans.map((plan) => (
            <Box w={{ base: "full", lg: "14rem" }} key={plan}>
              <PricePlanCard
                onClick={onSelectPricePlan}
                pricePlan={plan}
                selected={active === plan}
                narrow={isMdScreenUp}
                currency={currency}
              />
            </Box>
          ))}
        </Stack>
      )}
      {isPlanRequiringCsApproval && !isLocked ? (
        <Alert
          variant="info"
          mt="xl"
          maxW="85%"
          mx="auto"
          data-testid="price-plan-alert"
        >
          <Styled.p pl="m">
            Please get in touch with us at{" "}
            <Link href="mailto:sales@joinodin.com">sales@joinodin.com</Link> for
            creating deals under {mapPricePlanNameInDisclaimer(active)} plan.
          </Styled.p>
        </Alert>
      ) : null}
      {isLocked ? (
        <Typography variant="body" mt="4">
          <Link
            href="mailto:hello@joinodin.com?subject=Change subscription tier"
            isExternal
          >
            Contact us
          </Link>{" "}
          to discuss changes to your price plan.
        </Typography>
      ) : null}
    </Box>
  );
}
