/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Which party (if any) should voting be proxied to in the event that
the investee company needs to make a decision.

 */
export type ProxyVotingTarget =
  (typeof ProxyVotingTarget)[keyof typeof ProxyVotingTarget];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProxyVotingTarget = {
  syndicate_lead: "syndicate_lead",
  chair_of_board: "chair_of_board",
  founders: "founders",
  do_not_proxy: "do_not_proxy",
} as const;
