import { formatIsoDate } from "@internal/utils/dates";
import { Typography } from "components/atoms/Typography";
import { useFormContext } from "react-hook-form";

export function FormTermsSignedOn() {
  const { formState } = useFormContext();
  const { errors } = formState;
  const withError = errors && errors["signature"];

  return (
    <Typography
      variant="body"
      color="grey.gunsmoke"
      mt="s"
      transition="transform .2s ease-in-out"
      style={{ transform: withError ? "translateY(15px)" : undefined }}
    >
      Signed on{" "}
      {formatIsoDate(new Date().toISOString(), {
        dateStyle: "short",
      })}
    </Typography>
  );
}
