import { ErrorBoundary as HighlightErrorBoundary } from "@highlight-run/next/client";
import { deriveError } from "@internal/rest/client";
import { ErrorBanner } from "components/molecules/ErrorBanner/ErrorBanner";
import { useRouter } from "next/router";
import { ReactNode } from "react";

import { Content } from "./components/Content";
import { Links } from "./components/Links";
import { TiledBg } from "./components/TiledBg/TiledBg";

UnauthorizedLayout.Content = Content;
interface Props {
  children: ReactNode;
}

export function UnauthorizedLayout({ children }: Props) {
  const router = useRouter();
  const isOnboarding = router.pathname.includes("onboarding");

  return (
    <TiledBg
      p="m"
      minH="100vh"
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent={isOnboarding ? "flex-start" : "center"}
    >
      <HighlightErrorBoundary
        showDialog={false}
        fallback={(e) => {
          const error = deriveError(e);

          return (
            <UnauthorizedLayout.Content bgColor="grey.woodsmoke" p="0">
              <ErrorBanner
                fullError={error}
                correlationId={error.correlation_id ?? ""}
                userMsg={error.message ?? ""}
              />
            </UnauthorizedLayout.Content>
          );
        }}
      >
        {children}
      </HighlightErrorBoundary>
      <Links
        links={[
          {
            label: "Privacy",
            href: "https://www.joinodin.com/privacy",
            isExternal: true,
          },
          {
            label: "Terms",
            href: "https://www.joinodin.com/terms-and-conditions",
            isExternal: true,
          },
        ]}
      />
    </TiledBg>
  );
}
