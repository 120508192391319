import { Box, VStack } from "@@panda/jsx";
import {
  HydratedCarrySplit,
  LegalEntityType,
} from "@internal/rest/generated/schemas";
import { Button } from "components/atoms/Button";
import { Form } from "components/forms/Form/Form";
import { Dialog } from "components/molecules/Dialog";
import { useAuth } from "services/auth/AuthProvider";

import { useAddRecipientModal } from "./useAddRecipientModal";
import { CarryRecipient, FormFields } from "./utils";

interface Props {
  onAddRecipient: (entity: CarryRecipient) => void;
  recipients: HydratedCarrySplit;
}

export function AddRecipientModal({ onAddRecipient, recipients }: Props) {
  const {
    onClose,
    onSubmit,
    formValues,
    onFieldsChange,
    watchedFields,
    validateEmail,
    validateEmailWithDuplicate,
    validateCompanyNumber,
  } = useAddRecipientModal({
    onAddRecipient,
    recipients,
  });

  const shouldPrefill = recipients.length === 0;
  const auth = useAuth();

  return (
    <Form
      onSubmit={onSubmit}
      config={{
        defaultValues: shouldPrefill
          ? {
              [FormFields.FirstName]: auth.account?.first_name ?? "",
              [FormFields.LastName]: auth.account?.last_name ?? "",
              [FormFields.Email]: auth.account?.email ?? "",
            }
          : {},
      }}
      fieldsToWatch={watchedFields}
      onFieldsChange={onFieldsChange}
    >
      <VStack
        alignItems="flex-start"
        data-testid="add-recipient-form"
        gap="6"
        maxH="500px"
        overflowY="scroll"
      >
        <Form.Select
          name={FormFields.EntityType}
          fieldOpts={{ required: true }}
          label="Entity type"
        >
          <option value={LegalEntityType.individual}>Individual</option>
          <option value={LegalEntityType.business}>Company</option>
        </Form.Select>
        {formValues.entityType === "individual" ? (
          <>
            <Form.TextField
              name={FormFields.FirstName}
              placeholder="Steve"
              label="First name"
              fieldOpts={{ required: "Please enter a first name" }}
              autoFocus
            />
            <Form.TextField
              name={FormFields.LastName}
              placeholder="Jobs"
              label="Last name"
              fieldOpts={{ required: "Please enter a last name" }}
            />
            <Box mb="6" w="full">
              <Form.TextField
                name={FormFields.Email}
                placeholder="steve@apple.com"
                label="Email address"
                fieldOpts={{ validate: validateEmailWithDuplicate }}
              />
            </Box>
          </>
        ) : (
          <>
            <Form.TextField
              name={FormFields.CompanyTradeName}
              placeholder="Pied Piper"
              label="Company trade name"
              fieldOpts={{ required: "Please enter a company trade name" }}
            />
            <Form.TextField
              name={FormFields.CompanyLegalName}
              placeholder="Pied Piper Ltd"
              label="Company legal name"
              fieldOpts={{ required: "Please enter a company legal name" }}
            />
            <Form.TextField
              name={FormFields.CompanyNumber}
              placeholder="1234567"
              fieldOpts={{ validate: validateCompanyNumber }}
              label="Company registration number"
              subLabel="This will be as it appears on the company registry of the country of incorporation."
            />
            <Form.TextField
              name={FormFields.CompanyContactFirstName}
              placeholder="Steve"
              label="First name of company contact"
              fieldOpts={{ required: "Please enter the contact first name" }}
            />
            <Form.TextField
              name={FormFields.CompanyContactLastName}
              placeholder="Jobs"
              label="Last name of company contact"
              fieldOpts={{ required: "Please enter the contact last name" }}
            />
            <Form.TextField
              name={FormFields.CompanyContactEmail}
              placeholder="steve@apple.com"
              label="Email of company contact"
              fieldOpts={{ validate: validateEmail }}
              w="full"
            />
          </>
        )}
      </VStack>
      <Dialog.Footer>
        <Button onClick={onClose} variant="secondary" w="full">
          Cancel
        </Button>

        <Form.Submit m={0} w="full">
          Save
        </Form.Submit>
      </Dialog.Footer>
    </Form>
  );
}
