import { HStack, VStack } from "@@panda/jsx";
import { Thumbnail } from "components/atoms/Thumbnail";
import { Typography } from "components/atoms/Typography";

import { useHeroSection } from "./useHeroSection";

interface Props {
  companyLogo: string;
}

export function HeroSection(props: Props) {
  const section = useHeroSection();

  return (
    <VStack
      gap="m"
      alignItems="flex-start"
      mb="3rem"
      data-testid="hero-section"
    >
      <Typography variant="heading.3">Your SPV</Typography>
      <HStack>
        <Thumbnail
          alt={section.companyName}
          src={props.companyLogo}
          width="3rem"
          height="3rem"
          data-testid="avatar-preview"
        />
        <VStack gap="s" alignItems="flex-start">
          <Typography variant="body" lineHeight="tight">
            {section.companyName}
          </Typography>
          <Typography
            variant="subtext"
            color="grey.gunsmoke"
            lineHeight="tight"
          >
            {section.companyLegalName}
          </Typography>
        </VStack>
      </HStack>
    </VStack>
  );
}
