import { css, cx } from "@@panda/css";
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/atoms/Button";
import { Skeleton } from "components/atoms/Skeleton";
import { CreateDealModal } from "components/molecules/CreateDealModal";
import { EmptyDealsView } from "components/molecules/EmptyDealsView";
import { StatusBanner } from "components/molecules/StatusBanner";
import { useState } from "react";
import { getDealUrl, isDraft } from "screens/deals/utils";

import { TableCell } from "../TableHelpers/TableCell";
import { TableHeader } from "../TableHelpers/TableHeader";
import { tableStyles } from "../TableHelpers/styles";
import { Column, CreatedDeal, columns } from "./columns";
import { useCreatedDeals } from "./useCreatedDeals";

export function CreatedDeals() {
  const { data: deals, error, ready } = useCreatedDeals();
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    columns,
    // SafeCast: TS complaining `deals` missing `__typename` when is not.
    data: deals as CreatedDeal[],
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error) {
    return (
      <StatusBanner
        error={{
          message: "We had a problem displaying your deals",
          error: "We had a problem displaying your deals",
          correlation_id: "graphql_created",
        }}
      />
    );
  }

  if (ready && !deals.length) {
    return (
      <EmptyDealsView
        title="Launch a deal"
        subText="Raise and deploy capital seamlessly"
        cta={
          <CreateDealModal
            triggerSlot={<Button variant="secondary">Launch a deal</Button>}
          />
        }
      />
    );
  }

  return (
    <>
      <table className={css({ w: "full" })}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => {
                return (
                  <TableHeader
                    isFirst={i === 0}
                    onClick={header.column.getToggleSortingHandler()}
                    sorting={header.column.getIsSorted()}
                    canSort={header.column.getCanSort()}
                    key={header.id}
                    hiddenSm={i > 1}
                    alignRight={i > 1}
                    className={
                      header.id === Column.AmountReceived
                        ? css({
                            textAlign: { base: "right!", lg: "left!" },
                          })
                        : undefined
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className={css({ w: "full" })}>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                data-testid={row.original.tradeName}
                data-deal-id={row.original.id}
                className={cx(
                  tableStyles.row,
                  css({
                    _hover: {
                      '&:hover [data-company-name="true"]': {
                        borderBottomColor: "white",
                      },
                    },
                  })
                )}
              >
                {row.getVisibleCells().map((cell, i) => {
                  const isInDraft = isDraft(row.original.status);
                  const isDraftInReview = row.original.status === "REVIEW";
                  const dealUrl = getDealUrl({
                    id: row.original.id,
                    dealSlug: row.original.slug ?? "",
                    syndSlug: row.original.syndicate?.slug ?? "",
                    type: isDraftInReview
                      ? "review"
                      : isInDraft
                        ? "draft"
                        : "admin",
                  });

                  return (
                    <TableCell
                      key={cell.id}
                      dealUrl={dealUrl}
                      dealLinkText={`Go to ${row.original.tradeName}`}
                      testId={`${row.original.id}-${
                        // SafeCast: this maps to `Column` in our column definitions
                        cell.column.id as unknown as Column
                      }`}
                      hiddenSm={i > 1}
                      alignRight={i > 1}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!ready ? (
        <Skeleton
          count={5}
          className={css({
            height: "2.5rem",
            w: "full",
          })}
          containerClassName={css({
            display: "flex",
            flexDir: "column",
            gap: "xs",
            alignItems: "flex-start",
            pt: "l",
            w: "full",
          })}
        />
      ) : null}
    </>
  );
}
