/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type PricePlanFloorCapVariant =
  (typeof PricePlanFloorCapVariant)[keyof typeof PricePlanFloorCapVariant];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PricePlanFloorCapVariant = {
  floor_cap: "floor_cap",
} as const;
