import { VStack } from "@@panda/jsx";
import { Typography } from "components/atoms/Typography";
import { formLabelStyles } from "components/forms/Form/FormLabel/FormLabel";
import { RichText } from "components/molecules/RichText";
import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";

const defaultContent = `e.g. 'Investors will be subject to a 1% management fee'`;

interface Props {
  onSaveCustomTerms: (customTerms: string) => void;
}

export function CustomTermsField({ onSaveCustomTerms }: Props) {
  const { currentDraft } = useContext(CreateDealContext);
  const initialContent = currentDraft.terms?.custom_terms ?? "";

  return (
    <VStack
      alignItems="flex-start"
      gap="0"
      pb="m"
      data-testid="custom-terms-field"
    >
      <Typography className={formLabelStyles} variant="body" mb="0!">
        Custom terms
      </Typography>
      <Typography variant="subtext" color="grey.gunsmoke" pb="s">
        Include the exact wording for any custom terms you'd like investors to
        be subject to, outside of the standard terms outlined in our Syndicate
        Terms. The Custom Terms will be included in an extra section of the
        legals.
      </Typography>
      <RichText
        type="editor"
        initialContent={initialContent}
        placeholder={defaultContent}
        onChange={onSaveCustomTerms}
        tools="minimal"
      />
    </VStack>
  );
}
