import { cx } from "@@panda/css";
import * as RadixTabs from "@radix-ui/react-tabs";

import { useTabsInternalContext } from "../internalContext";

export function TabPanel<TValue extends string>({
  className,
  ...p
}: Omit<RadixTabs.TabsContentProps, "value"> & {
  value: TValue;
}) {
  const { classes } = useTabsInternalContext();
  return <RadixTabs.Content {...p} className={cx(classes.panel, className)} />;
}
