import { css } from "@@panda/css";
import { HTMLStyledProps } from "@@panda/jsx";
import { IconText } from "components/atoms/IconText";
import { Card } from "components/molecules/Card";
import { ComponentPropsWithRef, ReactNode } from "react";

interface Props extends HTMLStyledProps<"div"> {
  description: string;
  icon: (svgProps: ComponentPropsWithRef<"svg">) => ReactNode;
  selected: boolean;
  title: string;
  testId?: string;
  onClick: () => void;
  disabled?: boolean;
}

export function ModalOption({
  description,
  icon,
  selected,
  title,
  onClick,
  disabled = false,
  ...rest
}: Props) {
  return (
    <Card
      p="m"
      width="full"
      aria-selected={selected}
      aria-label={title}
      aria-description={description}
      borderRadius="md"
      userSelect="none"
      withBorder
      borderColor="grey.dune"
      className={css({
        _selected: {
          borderColor: "brand.krilin!",
        },
      })}
      {...rest}
      {...(disabled
        ? {
            disabled: true,
            opacity: 0.4,
            cursor: "not-allowed",
            withHover: false,
            pointerEvents: "none",
          }
        : { onClick, cursor: "pointer", withHover: true })}
    >
      <IconText
        selected={selected}
        title={title}
        description={description}
        icon={icon}
      />
    </Card>
  );
}
