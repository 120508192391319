export const formatIsoDate = (
  isoDateString: string | undefined | null,
  opts?: Intl.DateTimeFormatOptions
): string => {
  if (!isoDateString) return "";
  if (!isDateValid(new Date(isoDateString))) return "";

  return new Date(isoDateString).toLocaleString(undefined, {
    dateStyle: "medium",
    ...opts,
  });
};

function isDateValid(date: unknown): boolean {
  return date instanceof Date && !Number.isNaN(date.getTime());
}
