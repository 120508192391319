import { VStack, styled } from "@@panda/jsx";
import { zendesk } from "services/zendesk";

import { Link } from "../../atoms/Link";
import { getLayoutProps } from "../Layout/utils";

const mailTo = "mailto:hello@joinodin.com";
const helpCenterURL = "https://help.joinodin.com/";

export function Footer() {
  return (
    <styled.footer {...getLayoutProps()} data-print="false">
      <VStack gap="4" alignItems="flex-start">
        <styled.p color="grey.gunsmoke" fontSize="xs">
          If you need help, we&apos;re available on&nbsp;
          <styled.button
            color="brand.krilin"
            _hover={{ color: "brand.yamcha" }}
            fontWeight={600}
            onClick={() => zendesk.toggleChatWindow()}
          >
            Chat,
          </styled.button>
          <Link href={mailTo} isExternal>
            {" "}
            Email
          </Link>
          &nbsp;or you can check out our{" "}
          <Link href={helpCenterURL} isExternal>
            Help center
          </Link>
          .
        </styled.p>

        <styled.p
          fontSize="xs"
          color="grey.gunsmoke"
          paddingRight={{ base: "16", lg: "0" }}
        >
          Odin, Join Odin and www.joinodin.com are trading names of Talbot
          Capital Limited, which is authorised and regulated by the Financial
          Conduct Authority (FCA), FRN 489839. Access to the Odin platform is
          restricted only to those individuals who are able to first truthfully
          evidence that they are persons eligible to receive exempted financial
          promotions in respect of private investment opportunities in
          compliance with the Financial Services and Markets Act 2000 (Financial
          Promotion) Order 2005 (SI 2005/1529).
        </styled.p>

        <styled.p fontSize="xs" color="grey.gunsmoke" paddingRight="16">
          For further information, please contact{" "}
          <Link href="mailto:hello@joinodin.com" isExternal>
            hello@joinodin.com
          </Link>
          .
        </styled.p>
      </VStack>
    </styled.footer>
  );
}
