import { Box, VStack } from "@@panda/jsx";
import {
  BanknotesIcon,
  BuildingLibraryIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { Button } from "components/atoms/Button";
import { Tooltip } from "components/atoms/Tooltip";
import { Typography } from "components/atoms/Typography";
import { ModalOption } from "components/molecules/ModalOption";

import { useCreateDealOptions } from "./useCreateDealOptions";

export const createDealModalText = {
  cta: "Continue",
  options: {
    syndicatePrimary: {
      title: "Primary Deal",
      description: "Invest directly in a company with syndicate members.",
    },
    syndicateSecondary: {
      title: "Secondary Deal",
      description:
        "Purchase shares from company shareholders with syndicate members.",
    },
    founder: {
      title: "Raise a Round",
      description: "Pool your investors into a single entity (SPV).",
    },
  },
};

export function CreateDealOptions() {
  const {
    onContinueClick,
    isSubmitting,
    selectedOption,
    setSelectedOption,
    isLaunchingFromCommunity,
  } = useCreateDealOptions();

  return (
    <>
      <VStack gap="m" alignItems="flex-start">
        <Typography variant="heading.4">I'm a Syndicate Lead</Typography>
        <ModalOption
          testId="create-deal-syndicate-primary-option"
          title={createDealModalText.options.syndicatePrimary.title}
          description={createDealModalText.options.syndicatePrimary.description}
          icon={BuildingLibraryIcon}
          onClick={() => setSelectedOption("SYNDICATE-PRIMARY")}
          selected={selectedOption === "SYNDICATE-PRIMARY"}
        />
        <Tooltip label="Coming soon. Please contact us at hello@joinodin.com for more.">
          <Box cursor="not-allowed">
            <ModalOption
              testId="create-deal-syndicate-secondary-option"
              title={createDealModalText.options.syndicateSecondary.title}
              description={
                createDealModalText.options.syndicateSecondary.description
              }
              icon={UserGroupIcon}
              onClick={() => undefined}
              selected={false}
              disabled
            />
          </Box>
        </Tooltip>
        {isLaunchingFromCommunity ? null : (
          <>
            <Typography variant="heading.4">I'm a Founder</Typography>
            <ModalOption
              testId="create-deal-founder-option"
              title={createDealModalText.options.founder.title}
              description={createDealModalText.options.founder.description}
              icon={BanknotesIcon}
              onClick={() => setSelectedOption("FOUNDER")}
              selected={selectedOption === "FOUNDER"}
            />
          </>
        )}
      </VStack>

      <Button
        variant="primary"
        isDisabled={!selectedOption || isSubmitting}
        isLoading={isSubmitting}
        mt="2rem"
        width="full"
        type="submit"
        onClick={onContinueClick}
      >
        {createDealModalText.cta}
      </Button>
    </>
  );
}
