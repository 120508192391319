import { FileUpload as ArkFileUpload } from "@ark-ui/react";
import { deriveGqlError } from "@internal/utils/error";
import { ComponentProps, useState } from "react";

import { FileUpload } from ".";
import { FileError } from "./helpers";

export type FileState =
  | {
      status: "loading";
      fileName: string;
    }
  | { status: "uploaded"; fileName: string }
  | {
      status: "invalid";
      fileName: string;
      reason: FileError;
    }
  | {
      status: "failed";
      fileName: string;
      reason: string;
    };

type Args = Pick<
  ComponentProps<typeof FileUpload>,
  "previewUrl" | "onFileDrop"
>;

export const useFileUpload = (args: Args) => {
  const [fileState, setFileState] = useState<FileState | null>(null);

  const hasPreview = Boolean(args.previewUrl);
  const isUploading = fileState?.status === "loading";

  const contentStatus = (() => {
    if (isUploading) return "uploading";
    if (hasPreview) return "preview";
    return "default";
  })();

  const handleFileAccept = async ({
    files,
  }: ArkFileUpload.FileAcceptDetails) => {
    setFileState(null);

    files.forEach(async (file) => {
      try {
        setFileState({ status: "loading", fileName: file.name });

        await args.onFileDrop(file);

        setFileState({
          status: "uploaded",
          fileName: file.name,
        });
      } catch (e) {
        setFileState({
          status: "failed",
          fileName: file.name,
          reason: deriveGqlError(e) ?? "Unknown error",
        });
      }
    });
  };

  const handleFileReject = async ({
    files,
  }: ArkFileUpload.FileRejectDetails) => {
    if (!files.length) return;

    setFileState(null);

    files.forEach(async ({ errors, file }) => {
      setFileState({
        status: "invalid",
        fileName: file.name,
        reason: errors,
      });
    });
  };

  return {
    contentStatus,
    fileState,
    hasPreview,
    isUploading,
    handleFileReject,
    handleFileAccept,
  } as const;
};
