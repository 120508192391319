import { useEffect, useRef, useState } from "react";
import EditorBase from "react-avatar-editor";

import { ImgArgs } from ".";

const DEFAULT_ROTATION = 0;
const DEFAULT_SIZE = 1;
const DEFAULT_WEIGHT_LIMIT = 5000000; // expressed in bytes is 5MB

interface Args {
  onSaveAvatar: (img: ImgArgs) => Promise<void>;
  avatarUrl: string | undefined;
  weightLimit: number | undefined;
}

export function useAvatarEditor(args: Args) {
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [rotation, setRotation] = useState(DEFAULT_ROTATION);
  const [weightError, setWeightError] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [savedAvatar, setSavedAvatar] = useState<string | null>(null);
  const [isSavingAvatar, setSavingAvatar] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const editorRef = useRef<EditorBase>(null);
  const weightLimit = args.weightLimit || DEFAULT_WEIGHT_LIMIT;

  useEffect(() => {
    if (args.avatarUrl) {
      setSavedAvatar(args.avatarUrl);
    }
  }, [args.avatarUrl]);

  function reset() {
    setSelectedImage(null);
    setRotation(DEFAULT_ROTATION);
    setSize(DEFAULT_SIZE);
  }

  function handleChangeRotation(val: number | number[]) {
    if (val && typeof val === "number") setRotation(val);
  }

  function handleUploadImage(img: File) {
    if (img.size > weightLimit) {
      setWeightError(true);
    } else {
      setSelectedImage(img);
      setWeightError(false);
    }
  }

  async function handleSaveImage() {
    if (editorRef.current) {
      const imgBlob = (await processBlob(editorRef)) as Blob;
      const imgUrl = editorRef.current.getImage().toDataURL();

      setSavingAvatar(true);

      try {
        setWeightError(false);

        await args.onSaveAvatar({
          img: imgBlob,
          url: imgUrl,
        });

        setSavedAvatar(imgUrl);
        setSavingAvatar(false);
        setOpen(false);
        reset();
      } catch {
        setSavingAvatar(false);
      }
    }
  }

  function processBlob(ref: typeof editorRef) {
    return new Promise((res) => {
      if (!ref.current) return res(null);

      ref.current.getImage().toBlob((blob) => {
        res(blob);
      });
    });
  }

  return {
    isOpen,
    setOpen,
    selectedImage,
    savedAvatar,
    isSavingAvatar,
    editorRef,
    size,
    rotation,
    handleChangeSize: setSize,
    handleChangeRotation,
    handleUploadImage,
    handleSaveImage,
    weightConstraint: weightLimit,
    weightError,
  };
}
