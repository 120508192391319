import { DataRow } from "components/atoms/DataRow";

import { useSecondarySellerSection } from "./useSecondarySellerSection";

export function SecondarySellerSection() {
  const section = useSecondarySellerSection();

  return (
    <>
      <DataRow label="Seller name" value={section.sellerName} />
      <DataRow label="Seller email" value={section.sellerEmail} />
    </>
  );
}
