import { Box, HStack, VStack } from "@@panda/jsx";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { OdinLogo } from "components/atoms/OdinLogo";
import { Typography } from "components/atoms/Typography";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  title: string;
  tip: string;
}

export function StatusCover({ title, tip, children }: Props) {
  return (
    <Box px="2">
      <HStack w="full" justifyContent="center" mt="8" mb="20">
        <OdinLogo width="2.3rem" height="auto" />
      </HStack>
      <VStack gap="6" py="8" alignItems="center" justifyContent="center">
        <VStack gap="7">
          <HStack
            bg="purple.chalk"
            w="10"
            h="10"
            borderRadius="full"
            alignItems="center"
            justifyContent="center"
          >
            <InformationCircleIcon width="1rem" color="#9477F3" />
          </HStack>
          <VStack>
            <Typography variant="heading.2" textAlign="center" fontSize="xl">
              {title}
            </Typography>
            <Typography
              variant="body"
              textAlign="center"
              color="grey.gunsmoke"
              maxW="lg"
            >
              {tip}
            </Typography>
          </VStack>
        </VStack>
        {children}
      </VStack>
    </Box>
  );
}
