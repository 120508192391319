/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The internal deal status. Only for use by ops in Retool.

Deprecated: these internal statuses should be moved into real deal
statuses if the non-admin APIs or UIs are ever going to read them.

 * @deprecated
 */
export type InternalDealStatus =
  (typeof InternalDealStatus)[keyof typeof InternalDealStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalDealStatus = {
  published: "published",
  all_funds_in: "all_funds_in",
  awaiting_legals: "awaiting_legals",
  reviewing_legals: "reviewing_legals",
  funds_wired: "funds_wired",
  completed: "completed",
  cancelled: "cancelled",
} as const;
