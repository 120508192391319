import { Account } from "@internal/rest/generated/schemas";
import flagsmith from "flagsmith";
import { environment } from "services/environment";

import { Feature } from "./types";

export enum Flags {
  EXAMPLE = "example",
  INSTANT_DEALS = "instant_deals",
}

const isTest = environment.getNodeEnv() === "test";

export type FlagsShape = Record<Flags, Feature>;

export function getDefaultFlags(): FlagsShape {
  return {
    example: {
      enabled: false,
    },
    instant_deals: {
      enabled: false,
    },
  };
}

export async function init(account: Account) {
  if (isTest) return;

  if (!flagsmith.initialised) {
    await flagsmith.init({
      defaultFlags: getDefaultFlags(),
      environmentID: isTest
        ? "123"
        : (process.env["NEXT_PUBLIC_FLAGSMITH_KEY"] as string),
    });
  }

  if (
    flagsmith.getState().evaluationContext?.identity?.identifier !==
    account.email
  ) {
    await flagsmith.identify(account?.email, {
      admin: account.is_admin,
      email: account.email,
    });
  }
}

export async function logout() {
  await flagsmith.logout();
}

export function useFeatureFlag(flag: Flags) {
  const values = flagsmith.getAllFlags() as FlagsShape;
  return values?.[flag]?.enabled ?? false;
}
