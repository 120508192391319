import { Box } from "@@panda/jsx";
import {
  SyndicateLeadReference,
  SyndicateLeadReferenceList,
} from "@internal/rest/generated/schemas";
import { Button } from "components/atoms/Button";
import { Form } from "components/forms/Form/Form";
import { Dialog } from "components/molecules/Dialog";

import { inputMaxWidth } from "../../SelectSyndicateStep";
import { FormFields, FormLabels, useAddMemberForm } from "./useAddMemberForm";
import { validateDuplicateMembers } from "./utils";

interface Props {
  onAdd: (member: SyndicateLeadReference) => void;
  currentMembers: SyndicateLeadReferenceList;
}

export function AddMemberForm({ onAdd, currentMembers }: Props) {
  const { onClose, onSubmit } = useAddMemberForm({
    onAdd,
  });

  return (
    <Box data-testid="add-member-form">
      <Form onSubmit={onSubmit}>
        <Box>
          <Form.TextField
            name={FormFields.FirstName}
            placeholder="Patrick"
            label={FormLabels.FirstName}
            width={["full", inputMaxWidth]}
            fieldOpts={{ required: "Please enter a first name" }}
          />
        </Box>

        <Box mt="6">
          <Form.TextField
            name={FormFields.LastName}
            placeholder="Ryan"
            label={FormLabels.LastName}
            width={["full", inputMaxWidth]}
            fieldOpts={{ required: "Please enter a last name" }}
          />
        </Box>

        <Box mt="6">
          <Form.TextField
            name={FormFields.Email}
            placeholder="hello@joinodin.com"
            label={FormLabels.Email}
            width={["full", inputMaxWidth]}
            fieldOpts={{
              validate: (val: string) =>
                validateDuplicateMembers({ val, currentMembers }),
            }}
          />
        </Box>

        <Dialog.Footer>
          <Button
            onClick={onClose}
            variant="secondary"
            w="full"
            alignSelf="flex-end"
          >
            Cancel
          </Button>

          <Form.Submit w="full" m="0">
            Save
          </Form.Submit>
        </Dialog.Footer>
      </Form>
    </Box>
  );
}
