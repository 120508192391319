import { css } from "@@panda/css";
import { Center } from "@@panda/jsx";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Typography } from "components/atoms/Typography";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

import { Tooltip } from "../Tooltip";

interface Props {
  content: string;
  alt?: string;
}

export function InformationTooltip({ content, alt, ...rest }: Props) {
  const { isLgScreenUp } = useBreakpoint("lgUp");

  return isLgScreenUp ? (
    <Tooltip
      label={<Typography variant="body">{content}</Typography>}
      {...rest}
    >
      <Center height="1.3em" width="1.3em" aria-label={alt ?? content}>
        <InformationCircleIcon className={css({ color: "grey.gunsmoke" })} />
      </Center>
    </Tooltip>
  ) : null;
}
