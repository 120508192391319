import { Box, HStack, VStack, styled } from "@@panda/jsx";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";
import { UploadFile } from "components/atoms/UploadFile";
import { LegacyRef } from "react";
import EditorBase from "react-avatar-editor";

import { trimImageName } from "../utils";
import { SliderControl } from "./SliderControl";

const MB1 = 1000000;

export interface EditorProps {
  selectedImage: File | null;
  size: number;
  rotation: number;
  editorRef: LegacyRef<EditorBase>;
  weightError: boolean;
  weightConstraint: number;
  isSavingAvatar: boolean;
  onSaveImage: () => Promise<void>;
  onChangeRotation: (rotation: number | number[]) => void;
  onChangeSize: (size: number) => void;
  onUploadImage: (img: File) => void;
}

export function Editor(props: EditorProps) {
  const {
    selectedImage,
    editorRef,
    size,
    rotation,
    weightConstraint,
    weightError,
    onChangeSize,
    onChangeRotation,
    onUploadImage,
  } = props;

  return (
    <VStack gap="4" alignItems="flex-start" justifyContent="flex-start">
      {selectedImage ? (
        <Box overflow="hidden" borderRadius="lg">
          <EditorBase
            ref={editorRef}
            image={selectedImage}
            width={200}
            height={200}
            border={30}
            scale={size}
            rotate={rotation}
            color={[0, 0, 0, 0.7]}
            data-testid="react-avatar-editor"
          />
        </Box>
      ) : (
        <Box
          width={260}
          height={260}
          bgColor="grey.minecraft"
          borderRadius="lg"
          data-testid="no-image-selected-box"
        />
      )}

      <styled.p
        color="grey.alabaster"
        fontSize="xs"
        fontWeight="semibold!"
        display="flex"
        flexDir="row"
        gap="s"
        alignItems="center"
      >
        <CursorArrowRaysIcon width="1rem" color="white" /> You can drag the
        image to reposition it.
      </styled.p>

      <styled.p color="grey.gunsmoke" fontSize="xs">
        We recommend the uploaded image to be square and at least 500x500 to
        display correctly.
      </styled.p>

      {weightError ? (
        <styled.p fontSize="xs" color="yellow.vesuvius">
          The image you uploaded is too big. Please upload an image less than{" "}
          {weightConstraint / MB1}MB.
        </styled.p>
      ) : null}
      <Box data-testid="zoom-slider" w="full">
        <SliderControl
          onChange={(val: number | number[]) => {
            if (val && typeof val === "number") onChangeSize(val / 50 + 1);
          }}
          label="Zoom"
          defaultValue={[size]}
          isDisabled={!selectedImage}
        />
      </Box>
      <Box data-testid="rotation-slider" w="full">
        <SliderControl
          min={0}
          max={360}
          defaultValue={[0]}
          onChange={onChangeRotation}
          label="Rotation"
          isDisabled={!selectedImage}
        />
      </Box>

      <HStack justifyContent="flex-start" alignItems="center" width="full">
        <UploadFile
          label="Upload an image"
          accept="image/png, image/jpeg"
          name="upload-image"
          id="upload-image"
          onChange={onUploadImage}
        />
        {selectedImage ? (
          <styled.span fontSize="xs" pr="4" display="block">
            {trimImageName(selectedImage.name)}
          </styled.span>
        ) : null}
      </HStack>
    </VStack>
  );
}
