import {
  InvestmentInstrumentEquity,
  InvestmentInstrumentPrimary,
  InvestmentInstrumentSecondary,
  KybStatus,
  MonetaryValue,
} from "@internal/gql/generated/types";
import { InvestmentType } from "@internal/rest/generated/schemas";

export function formatInvestmentTypeREST(
  ivt: InvestmentType | undefined
): string {
  switch (ivt) {
    case InvestmentType.convertible:
      return "Convertible note";
    case InvestmentType.equity:
      return "Equity";
    case InvestmentType.safe:
      return "SAFE";
    case InvestmentType.saft:
      return "SAFT";
    case InvestmentType.seedfast:
      return "ASA";
    case InvestmentType.fund_investment:
      return "Fund investment";
    case InvestmentType.secondary:
      return "Secondary";
    default:
      return "-";
  }
}

type Instrument =
  | InvestmentInstrumentPrimary["__typename"]
  | InvestmentInstrumentSecondary["__typename"];

export function formatInvestmentTypeGQL(instr: Instrument | undefined): string {
  switch (instr) {
    case "InvestmentInstrumentConvertible":
      return "Convertible note";
    case "InvestmentInstrumentEquity":
      return "Equity";
    case "InvestmentInstrumentSafe":
      return "SAFE";
    case "InvestmentInstrumentSaft":
      return "SAFT";
    case "InvestmentInstrumentSeedfast":
      return "ASA";
    case "InvestmentInstrumentSecondary":
      return "Secondary";
    default:
      return "–";
  }
}

type EquityInstrument =
  | (Pick<InvestmentInstrumentEquity, "sharePrice" | "__typename"> &
      Omit<Partial<InvestmentInstrumentEquity>, "sharePrice" | "__typename">)
  | {
      __typename: "InvestmentInstrumentConvertible";
    }
  | {
      __typename: "InvestmentInstrumentSafe";
    }
  | {
      __typename: "InvestmentInstrumentNote";
    }
  | {
      __typename: "InvestmentInstrumentSaft";
    }
  | {
      __typename: "InvestmentInstrumentSeedfast";
    };

type SecondaryInstrument = Pick<
  InvestmentInstrumentSecondary,
  "sharePrice" | "__typename"
> &
  Omit<Partial<InvestmentInstrumentSecondary>, "sharePrice" | "__typename">;

type PossibleInstrument =
  | EquityInstrument
  | SecondaryInstrument
  | null
  | undefined;

type PossibleReturn = MonetaryValue | null;

export function getSharePrice(instrument: PossibleInstrument): PossibleReturn {
  if (!instrument) return null;
  if (instrument.__typename === "InvestmentInstrumentEquity") {
    return instrument.sharePrice;
  }
  if (instrument.__typename === "InvestmentInstrumentSecondary") {
    return instrument.sharePrice;
  }

  return null;
}

export function computeDealNeedsInvesteeKYC(
  type: "Primary" | "Secondary",
  investeeKYCStatus: KybStatus | null
): boolean {
  return type === "Primary" ? investeeKYCStatus === "REQUIRED" : false;
}
