import { Box, VStack } from "@@panda/jsx";
import { Button } from "components/atoms/Button";
import { Typography } from "components/atoms/Typography";
import { Form } from "components/forms/Form/Form";
import { FormLabel } from "components/forms/Form/FormLabel/FormLabel";
import { Dialog } from "components/molecules/Dialog";
import { RichText } from "components/molecules/RichText";

import { StepSubmit } from "../../components/StepSubmit/StepSubmit";
import { DeckField } from "./components/DeckField/DeckField";
import { InvestmentAgreementField } from "./components/InvestmentAgreementField/InvestmentAgreementField";
import { useMemoMaterialsStep } from "./useMemoMaterialsStep";
import { MemoMaterialsSchema } from "./utils";

export const inputMaxWidth = "64!";

export function MemoMaterialsStep() {
  const { initialValues, onSubmit, onEditMemo, currentMemo, isSubmitting } =
    useMemoMaterialsStep();

  return (
    <Form
      onSubmit={onSubmit}
      config={{
        schema: MemoMaterialsSchema,
        defaultValues: initialValues.form,
        mode: "onTouched",
      }}
    >
      <VStack alignItems="flex-start" gap="3.5rem" w="full">
        <Box w="full">
          <FormLabel fontSize="md" mb="0!">
            Memo
          </FormLabel>
          <Typography variant="subtext" color="grey.gunsmoke" mb="m">
            Describe what the deal is about.{" "}
            <Dialog title="Memo preview" styling={{ width: "60vw" }}>
              <Dialog.Trigger>
                <Button
                  variant="link"
                  mb="xs"
                  data-testid="memo-preview-button"
                  height="auto"
                >
                  Click here to preview
                </Button>
              </Dialog.Trigger>
              <Dialog.Content>
                <Box overflow="auto" pr="m" pl="s">
                  <RichText type="viewer" initialContent={currentMemo} />
                </Box>
              </Dialog.Content>
            </Dialog>{" "}
            how the memo will look on the deal page.
          </Typography>

          <RichText
            type="editor"
            initialContent={initialValues.memo}
            onChange={onEditMemo}
          />
        </Box>

        <DeckField />
        <InvestmentAgreementField />
      </VStack>
      <Box pt="2rem">
        <StepSubmit isLoading={isSubmitting} />
      </Box>
    </Form>
  );
}
