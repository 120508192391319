import { Typography, TypographyProps } from "components/atoms/Typography";

export function FormSubLabel({
  children,
  ...rest
}: Omit<TypographyProps, "variant">) {
  return (
    <Typography
      variant="subtext"
      fontWeight="normal"
      color="grey.gunsmoke"
      mb="s"
      as="span"
      display="block"
      {...rest}
    >
      {children}
    </Typography>
  );
}
