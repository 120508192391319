/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Assessment required indicates that the account holder hasn't completed
the appropriateness assessment.

 */
export type AccountStatusAssessmentRequired =
  (typeof AccountStatusAssessmentRequired)[keyof typeof AccountStatusAssessmentRequired];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatusAssessmentRequired = {
  assessment_required: "assessment_required",
} as const;
