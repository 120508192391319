import { Box, VStack, styled } from "@@panda/jsx";
import { GetFormLabels } from "@internal/utils/types";
import { Button } from "components/atoms/Button";
import { Typography } from "components/atoms/Typography";
import { Form } from "components/forms/Form/Form";
import { AvatarEditor } from "components/molecules/AvatarEditor";
import { LoadingBanner } from "components/molecules/LoadingBanner/LoadingBanner";
import { StepWrap } from "screens/onboarding/components/StepWrap";
import { createNameAndLabels } from "utility/forms";
import { z } from "zod";

import { useInvestorDetailsStep } from "./useInvestorDetailsStep";

export type FormValues = z.infer<typeof formSchema>;
export type FormLabel = GetFormLabels<typeof getNameAndLabel>;
export type FormField = keyof FormValues;

export function InvestorDetailsStep() {
  const {
    onSubmit,
    isSubmitting,
    ready,
    avatar,
    onSaveAvatar,
    isUsernameLoading,
    data: { firstName, lastName },
  } = useInvestorDetailsStep();

  if (!ready) return <LoadingBanner />;

  return (
    <StepWrap>
      <VStack gap="6" mb="8">
        <VStack textAlign="center">
          <Typography variant="heading.2">Your profile on Odin</Typography>

          <Typography variant="body" color="grey.gunsmoke">
            This is how deal leads and co-investors will see you.
          </Typography>
        </VStack>

        <AvatarEditor avatarUrl={avatar} onSaveAvatar={onSaveAvatar}>
          {({ avatarUrl, openModal, withError }) => (
            <VStack gap="4">
              {avatarUrl ? (
                <styled.img
                  src={avatarUrl}
                  width="20"
                  height="20"
                  bg="black"
                  borderRadius="full"
                  data-testid="avatar-preview"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor={withError ? "red-salmon" : "grey.woodsmoke"}
                />
              ) : (
                <Box
                  width="12"
                  height="12"
                  bg="white"
                  borderRadius="base"
                  data-testid="avatar-preview-empty"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderColor={withError ? "red-salmon" : "grey.woodsmoke"}
                />
              )}
              <Button onClick={openModal} variant="secondary" size="sm">
                Upload profile photo
              </Button>
            </VStack>
          )}
        </AvatarEditor>
      </VStack>
      <Form
        onSubmit={onSubmit}
        config={{
          schema: formSchema,
          defaultValues: { firstName, lastName },
        }}
      >
        <VStack alignItems="flex-start" gap="6" mb="6">
          <Form.TextField
            autoFocus
            autoComplete="given-name"
            {...getNameAndLabel("firstName")}
            placeholder="Join"
          />
          <Form.TextField
            autoComplete="family-name"
            {...getNameAndLabel("lastName")}
            placeholder="Odin"
          />
        </VStack>
        <Form.Submit
          isDisabled={isUsernameLoading}
          isLoading={isSubmitting}
          width="full"
        >
          Continue
        </Form.Submit>
      </Form>
    </StepWrap>
  );
}

const formSchema = z.object({
  firstName: z.string().min(2, "Please enter your first name"),
  lastName: z.string().min(2, "Please enter your last name"),
});

const { getNameAndLabel } = createNameAndLabels(formSchema, {
  firstName: "First name*",
  lastName: "Last name*",
});
