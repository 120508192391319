import { VStack } from "@@panda/jsx";
import { Button } from "components/atoms/Button";
import { Typography } from "components/atoms/Typography";
import { StepWrap } from "screens/onboarding/components/StepWrap";

import { useAssessmentFailure } from "./useAssessmentFailure";

export function AssessmentFailure() {
  const { onStartAgain } = useAssessmentFailure();

  return (
    <StepWrap>
      <VStack gap="l" w="full">
        <Typography variant="heading.2" textAlign="center">
          Sorry, but we can’t let you invest
        </Typography>

        <Typography
          variant="body"
          color="grey.gunsmoke"
          pb="4"
          textAlign="center"
        >
          We are required to ask you to certify that you fulfil the criteria
          required to invest on Odin.
          <br />
          <br /> Unfortunately we can’t allow you to invest through Odin as you
          do not fulfil either of the categories.
          <br />
          <br /> If you made a mistake, you can go back and select your investor
          category.
        </Typography>

        <Button width="full" onClick={onStartAgain}>
          Go back
        </Button>
      </VStack>
    </StepWrap>
  );
}
