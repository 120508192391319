/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The kind of shares at play in the deal. More information on the meaning:
https://kruzeconsulting.com/blog/primary-secondary-shares/

 */
export type TransactionType =
  (typeof TransactionType)[keyof typeof TransactionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionType = {
  primary: "primary",
  secondary: "secondary",
} as const;
