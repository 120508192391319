import { Styled } from "components/styled";

import { LoggedInView } from "./components/LoggedInView/LoggedInView";
import { LoggedOutView } from "./components/LoggedOutView";
import { useNavigation } from "./useNavigation";

export const NAV_SIZE = "4rem";
export const NAV_ICON_SIZE_LG = "1.875rem";
export const NAV_ICON_SIZE_SM = "1.5rem";

export function Navigation() {
  const { isUserLoggedIn } = useNavigation();

  return (
    <Styled.nav
      bgColor="grey.bunker"
      position="fixed"
      borderStyle="solid"
      borderColor="grey.dune !important"
      borderRightWidth={{ base: "0", lg: "1px" }}
      borderTopWidth={{ base: "1px", lg: "0" }}
      left="0"
      top={{ base: "unset", lg: "0" }}
      bottom={{ base: "0", lg: "unset" }}
      width={{ base: "full", lg: NAV_SIZE }}
      height={{ base: NAV_SIZE, lg: "full" }}
      zIndex="nav"
      data-print="false"
    >
      {isUserLoggedIn ? <LoggedInView /> : <LoggedOutView />}
    </Styled.nav>
  );
}
