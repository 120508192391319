import { Box, VStack } from "@@panda/jsx";
import { DataRow } from "components/atoms/DataRow";
import { LoadingBanner } from "components/molecules/LoadingBanner/LoadingBanner";
import { PricePlanCard } from "components/molecules/PricePlanCard";
import { FeesTable } from "screens/create-deal/components/FeesTable";
import { isLegacyPlanWithAdditionalFees } from "utility/pricing";

import { usePlatformAndAdditionalFees } from "./usePricingSection";

export function PricingSection() {
  const { selectedPricePlan, currency, odinPlatformFeePayee, isFounderDeal } =
    usePlatformAndAdditionalFees();

  if (!selectedPricePlan || !currency) {
    return <LoadingBanner />;
  }

  return (
    <VStack gap="m" alignItems="start" w="full" data-testid="pricing-section">
      {isFounderDeal ? null : (
        <DataRow
          label="Who pays Odin's platform fee?"
          value={odinPlatformFeePayee}
        />
      )}

      <PricePlanCard
        readonly
        pricePlan={selectedPricePlan}
        currency={currency}
        w="full"
      />
      {isLegacyPlanWithAdditionalFees(selectedPricePlan) ? (
        <Box pt="m">
          <FeesTable pricePlan={selectedPricePlan} currency={currency} />
        </Box>
      ) : null}
    </VStack>
  );
}
