import {
  HydratedCarrySplit,
  LegalEntityInfoBusiness,
  LegalEntityType,
} from "@internal/rest/generated/schemas";
import { useDialogCtx } from "components/molecules/Dialog/useDialogCtx";
import { useState } from "react";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { z } from "zod";

import { getLegalEntityEmail } from "../../utils";
import {
  CarryRecipient,
  FormFields,
  RawFormValues,
  computeFormLegalEntity,
} from "./utils";

interface Args {
  onAddRecipient: (entity: CarryRecipient) => void;
  recipients: HydratedCarrySplit;
}

const watchedFields = [FormFields.EntityType];

export function useAddRecipientModal(args: Args) {
  const { handleClose } = useDialogCtx();

  const [entityType, setEntityType] = useState<LegalEntityType>("individual");

  const existingIndividuals = args.recipients
    .filter((r) => r.legal_entity.type === "individual")
    .map((r) => getLegalEntityEmail(r.legal_entity).toLowerCase());

  const existingCompanies = args.recipients
    .filter((r) => r.legal_entity.type === "business")
    .map((r) => {
      const recipient = r.legal_entity as LegalEntityInfoBusiness; // to make TS aware...
      return recipient.company_number;
    });

  function onFieldsChange(
    fields: string[] | null,
    _clearErrors: UseFormClearErrors<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    reset: UseFormReset<FieldValues>
  ) {
    if (!fields) return;

    const newEntityType = fields[0] as LegalEntityType;

    if (newEntityType !== entityType && typeof newEntityType !== "undefined") {
      setEntityType(newEntityType);
      reset();
      setValue(FormFields.EntityType, newEntityType);
    }
  }

  function onSubmit(values: RawFormValues) {
    const parsedValues = computeFormLegalEntity(values);
    args.onAddRecipient(parsedValues);
    handleClose();
  }

  function validateEmail(val: string) {
    const schema = z.string().email();
    const result = schema.safeParse(val);
    if (!result.success) return "Please enter a valid email";
    return true;
  }

  function validateEmailWithDuplicate(val: string) {
    const schema = z.string().email();
    const result = schema.safeParse(val);
    if (!result.success) return "Please enter a valid email";

    if (existingIndividuals.includes(val.toLowerCase()))
      return "This carry recipient has already been added";

    return true;
  }

  function validateCompanyNumber(val: string) {
    if (!val) return "Please enter a company number";
    if (existingCompanies.includes(val.toLowerCase()))
      return "This carry recipient has already been added";

    return true;
  }

  return {
    onClose: handleClose,
    onSubmit,
    onFieldsChange,
    watchedFields,
    validateEmail,
    validateEmailWithDuplicate,
    validateCompanyNumber,
    formValues: {
      entityType,
    },
  };
}
