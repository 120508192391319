import { Box, BoxProps, HStack, Stack, VStack } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";
import { getCurrencySign } from "@internal/utils/currency";
import { Link } from "components/atoms/Link";
import { Radio } from "components/atoms/Radio";
import { Typography } from "components/atoms/Typography";
import { isFounderPricing } from "utility/pricing";

import {
  PricePlanColors,
  PricePlanValues,
  getLabelFromPricePlan,
  isLegacy2023PricePlan,
} from "./utils";

export interface Props extends Omit<BoxProps, "onClick"> {
  currency?: BaseCurrency;
  disabled?: boolean;
  onClick?: (plan: PricePlanName) => void;
  pricePlan: PricePlanName;
  readonly?: boolean;
  selected?: boolean;
  narrow?: boolean;
}

export function PricePlanCard(props: Props) {
  const {
    // NOTE: We currently only display prices for GBP as that's our main market.
    // Other currencies are handled manually, by the sales and operations teams.
    currency = BaseCurrency.gbp,
    onClick,
    pricePlan,
    selected = false,
    readonly = false,
    narrow = false,
    ...rest
  } = props;

  const actualCurrency = currency === "usdc" ? "usd" : currency;
  const pricePlanValues = PricePlanValues[pricePlan];
  const pricePlanPrices = pricePlanValues.prices[actualCurrency];
  const currencySymbol = getCurrencySign(currency);
  const isPrivatePricing = Boolean(pricePlanPrices.tbd);
  const isFounder = isFounderPricing(pricePlan);
  const isLegacy2023 = isLegacy2023PricePlan(pricePlan);

  const radioBtn = readonly ? null : (
    <Radio
      data-testid={`price-plan-item-radio-${pricePlan}`}
      checked={selected}
      value={pricePlan}
      name={pricePlan}
      size={narrow ? "md" : "xl"}
      hideLabel
    />
  );

  return (
    <Box
      data-testid={`price-plan-item-${pricePlan}`}
      aria-selected={selected}
      p="0"
      bgColor="grey.woodsmoke"
      borderRadius="md"
      w="full"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="grey.dune"
      _selected={{
        borderColor: "brand.krilin",
      }}
      {...(readonly
        ? null
        : {
            onClick: () => onClick?.(pricePlan),
            role: "button",
            cursor: "pointer",
            userSelect: "none",
          })}
      {...rest}
    >
      <HStack
        justifyContent="space-between"
        alignItems="flex-start"
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderColor="grey.dune"
        p={narrow ? "m" : "m"}
      >
        <VStack alignItems="flex-start" gap="0.25rem" pb="xs">
          <HStack
            gap="xs"
            justifyContent="space-between"
            alignItems="center"
            w="full"
          >
            <Typography
              variant="body"
              data-testid={`price-plan-item-plan-name-${pricePlan}`}
              fontSize="md"
              fontWeight="700!"
              lineHeight="tight"
              style={{ color: token(PricePlanColors[pricePlan]) }}
            >
              {getLabelFromPricePlan(pricePlan)}
            </Typography>
            {narrow ? radioBtn : null}
          </HStack>
          <Typography
            variant="subtext"
            data-testid={`price-plan-item-description-${pricePlan}`}
            fontWeight="500"
            color="grey.gunsmoke"
            fontSize="xs"
            pt="s"
          >
            {pricePlanValues.description || ""}
          </Typography>
        </VStack>
        {narrow ? null : radioBtn}
      </HStack>
      {isPrivatePricing ? (
        <HStack
          px={narrow ? "s" : ["m", "m", "3.5rem"]}
          py="l"
          w="full"
          minH={narrow ? "15.35rem" : "5.625rem"}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="body"
            data-testid={`price-plan-item-subscription-${pricePlan}`}
            fontSize="sm"
            fontWeight="500"
            lineHeight={narrow ? "relaxed" : "tight"}
            textAlign="center"
          >
            Chat to us at{" "}
            <Link
              href="mailto:sales@joinodin.com"
              whiteSpace="nowrap"
              isExternal
            >
              sales@joinodin.com
            </Link>
          </Typography>
        </HStack>
      ) : isFounder ? (
        <HStack
          px={narrow ? "s" : ["m", "m", "3.5rem"]}
          py="l"
          w="full"
          minH={narrow ? "15.35rem" : "5.625rem"}
          justifyContent="center"
          alignItems="center"
        >
          <VStack gap="0.5" width={narrow ? "80%" : "32%"} mx="auto">
            <Typography
              variant="body"
              data-testid={`price-plan-item-percentage-${pricePlan}`}
              fontSize={narrow ? "md" : ["md", "md", "md", "xl"]}
              fontWeight="700"
              lineHeight="tight"
            >
              {currencySymbol}
              {pricePlanPrices.price}
            </Typography>
            <Typography
              variant="subtext"
              color="grey.gunsmoke"
              fontWeight="500"
              textAlign="center"
            >
              Flat fee per deal
            </Typography>
          </VStack>
        </HStack>
      ) : (
        <Stack
          flexDir={narrow ? "column" : "row"}
          justifyContent={["space-around", "space-around", "space-between"]}
          alignItems={["baseline", "baseline", "flex-end"]}
          w="full"
          py={narrow ? "m" : "l"}
          px={narrow ? "s" : ["m", "m", "3.5rem"]}
          gap="m"
        >
          <VStack gap="0.5" width={narrow ? "80%" : "32%"} mx="auto">
            <Typography
              variant="body"
              data-testid={`price-plan-item-subscription-${pricePlan}`}
              fontSize={narrow ? "md" : ["md", "md", "md", "xl"]}
              fontWeight="700"
              lineHeight="tight"
            >
              {currencySymbol}
              {pricePlanPrices.price}
            </Typography>
            <Typography
              variant="subtext"
              color="grey.gunsmoke"
              fontWeight="500"
              textAlign="center"
            >
              {isLegacy2023 ? "Annual subscription" : "Setup fee"}
            </Typography>
          </VStack>
          <VStack gap="0.5" width={narrow ? "80%" : "32%"} mx="auto">
            <Typography
              variant="body"
              data-testid={`price-plan-item-percentage-${pricePlan}`}
              fontSize={narrow ? "md" : ["md", "md", "md", "xl"]}
              fontWeight="700"
              lineHeight="tight"
            >
              {pricePlanValues.plusPercentageFundsRaised}%
            </Typography>
            <Typography
              variant="subtext"
              color="grey.gunsmoke"
              fontWeight="500"
              textAlign="center"
            >
              Fee % per deal
            </Typography>
          </VStack>
          {narrow ? (
            <VStack gap="0.5" width={narrow ? "80%" : "32%"} mx="auto">
              <Typography
                variant="body"
                data-testid={`price-plan-item-min-cap-${pricePlan}`}
                fontSize={narrow ? "md" : ["md", "md", "md", "xl"]}
                fontWeight="700"
                lineHeight="tight"
              >
                {currencySymbol}
                {pricePlanPrices.floor}
              </Typography>
              <Typography
                variant="subtext"
                color="grey.gunsmoke"
                fontWeight="500"
                textAlign="center"
              >
                Minimum fee per deal
              </Typography>
            </VStack>
          ) : null}
          <VStack gap="0.5" width={narrow ? "80%" : "32%"} mx="auto">
            <Typography
              variant="body"
              data-testid={`price-plan-item-min-cap-${pricePlan}`}
              fontSize={narrow ? "md" : ["md", "md", "md", "xl"]}
              fontWeight="700"
              lineHeight="tight"
            >
              {currencySymbol}
              {pricePlanPrices.cappedAt}
            </Typography>
            <Typography
              variant="subtext"
              color="grey.gunsmoke"
              fontWeight="500"
              textAlign="center"
            >
              Fee cap per deal
            </Typography>
          </VStack>
        </Stack>
      )}
      {narrow ? null : (
        <HStack
          justifyContent="center"
          borderTopWidth="1px"
          borderTopStyle="solid"
          borderColor="grey.dune"
          p="m"
          minH={narrow ? "6.20rem" : "auto"}
          alignItems="flex-start"
        >
          {isPrivatePricing ? (
            <Typography
              variant="subtext"
              data-testid={`price-plan-item-floor-${pricePlan}`}
              fontWeight="400"
              fontStyle="italic"
              lineHeight="1.4"
              fontSize={["xs", "xs", "sm"]}
              color="grey.gunsmoke"
            >
              Our team will reach out to you once you submit a deal.
            </Typography>
          ) : isFounder ? (
            <Typography
              variant="subtext"
              data-testid={`price-plan-item-floor-${pricePlan}`}
              fontWeight="400"
              fontStyle="italic"
              lineHeight="1.4"
              fontSize={["xs", "xs", "sm"]}
              color="grey.gunsmoke"
            >
              For founders looking to pool investors into one line on their cap
              table
            </Typography>
          ) : (
            <Typography
              variant="subtext"
              data-testid={`price-plan-item-floor-${pricePlan}`}
              fontWeight="400"
              fontStyle={narrow ? "normal" : "italic"}
              lineHeight="1.4"
              fontSize={["xs", "xs", "sm"]}
              color="grey.gunsmoke"
            >
              A minimum deal fee of{" "}
              <Typography variant="body" as="span" fontWeight="700">
                {currencySymbol}
                {pricePlanPrices.floor}
              </Typography>{" "}
              will apply for all deals.
            </Typography>
          )}
        </HStack>
      )}
    </Box>
  );
}
