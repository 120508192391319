import { css } from "@@panda/css";
import { VStack } from "@@panda/jsx";
import { Button } from "components/atoms/Button";
import { Link } from "components/atoms/Link";
import { Typography } from "components/atoms/Typography";
import { Alert } from "components/molecules/Alert";
import { Styled } from "components/styled";
import { ReactNode } from "react";
import { StepWrap } from "screens/onboarding/components/StepWrap";

type HardFailedProps = {
  hasHardFailed: true;
};

type SoftFailedProps = {
  hasHardFailed: false;
  attempts: number;
  handleReset: () => void;
};

type Props = HardFailedProps | SoftFailedProps;

export function FailedState(props: Props) {
  const { hasHardFailed } = props;

  const c = copy[hasHardFailed ? "hardFail" : "softFail"];

  return (
    <StepWrap>
      <VStack gap="l" w="full">
        <Typography variant="heading.2" textAlign="center">
          {c.heading}
        </Typography>

        <Typography variant="body" color="grey.alabaster" textAlign="center">
          {c.body1}
          <br />
          {c.body2}
          <br />
          <br />
          {c.body3}
        </Typography>

        {hasHardFailed ? (
          <Button
            as="a"
            width="full"
            href="mailto:?cc=hello%40joinodin.com&subject=Failed%20Appropriateness%20Assessment"
          >
            Contact us
          </Button>
        ) : (
          <>
            <Button width="full" onClick={() => props.handleReset()}>
              Try again
            </Button>

            <Styled.p
              fontWeight={600}
            >{`${3 - props.attempts} ${3 - props.attempts === 1 ? "attempt" : "attempts"} remaining...`}</Styled.p>
          </>
        )}
      </VStack>
    </StepWrap>
  );
}

const copy = {
  hardFail: {
    heading: "We cannot grant you access",
    body1: (
      <>
        Unfortunately you did not answer all questions correctly.
        <br />
      </>
    ),
    body2: (
      <>
        As a regulated investment platform, we are required to ensure all
        investors understand the risks of making investments on Odin through a
        series of questions that meet{" "}
        <a
          className={css({ textDecoration: "underline" })}
          target="_blank"
          href="https://www.handbook.fca.org.uk/handbook/COBS/10/?view=chapter"
          rel="noreferrer"
        >
          FCA standards.
        </a>
      </>
    ),
    body3:
      "If you believe there is an issue, please reach out by clicking below.",
  },
  softFail: {
    heading: "Some answers were incorrect",
    body1: (
      <Alert variant="info" fontWeight={600}>
        You must answer all questions correctly in order to invest on Odin.
      </Alert>
    ),
    body2:
      "You need to retake this assessment so we can ensure your suitability to invest on Odin.",
    body3: (
      <Styled.span color="grey.alabaster">
        <Link
          isExternal
          variant="secondary"
          href="https://help.joinodin.com/hc/en-gb/articles/25649883451793-Are-there-any-risks-in-using-Odin"
        >
          Please review this article
        </Link>{" "}
        before clicking continue and take your time.
      </Styled.span>
    ),
  },
} satisfies Record<
  "softFail" | "hardFail",
  Record<"heading" | "body1" | "body2" | "body3", ReactNode>
>;
