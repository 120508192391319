import { css } from "@@panda/css";
import { Box, VStack } from "@@panda/jsx";
import { PlatformFeeType } from "@internal/rest/generated/schemas";
import { Typography } from "components/atoms/Typography";
import { Form } from "components/forms/Form/Form";
import { LoadingBanner } from "components/molecules/LoadingBanner/LoadingBanner";
import { PricePlanCard } from "components/molecules/PricePlanCard";
import { useFormContext } from "react-hook-form";
import { FeesTable } from "screens/create-deal/components/FeesTable";
import { StepSubmit } from "screens/create-deal/components/StepSubmit/StepSubmit";
import { InlineContext } from "utility/InlineContext";
import { formatOdinPlatformFeePayee } from "utility/deals";
import { isLegacyPlanWithAdditionalFees } from "utility/pricing";

import { inputMaxWidth } from "../Carry/CarryStep";
import { getNameAndLabel } from "./helpers";
import { usePricingStep } from "./usePricingStep";

export function PricingStep() {
  const {
    onSubmit,
    isSecondary,
    selectedPricePlan,
    defaultValues,
    currency,
    isSubmitting,
  } = usePricingStep();

  if (!selectedPricePlan || !currency) {
    // should be set from prior steps
    return <LoadingBanner />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      config={{
        defaultValues,
      }}
    >
      <VStack gap="3.5rem" alignItems="start">
        <section className={css({ w: "full" })}>
          <Typography variant="heading.3" className={css({ pb: "1rem" })}>
            Platform Fees
          </Typography>

          <PricePlanCard
            readonly
            pricePlan={selectedPricePlan}
            currency={currency}
          />
          {isLegacyPlanWithAdditionalFees(selectedPricePlan) ? (
            <Box pt="l">
              <FeesTable pricePlan={selectedPricePlan} currency={currency} />
            </Box>
          ) : null}
        </section>

        <InlineContext
          hook={useFormContext}
          renderWithCtx={(ctx) => {
            const platformFeeValue = ctx.watch("platformFeeCovered");

            return (
              <Form.Select
                {...getNameAndLabel("platformFeeCovered")}
                selectContainerStyle={{ maxWidth: inputMaxWidth }}
                subLabel={
                  platformFeeValue === PlatformFeeType.investors_pro_rata
                    ? "This will be prorated across the SPV and deducted from their total funds received."
                    : null
                }
              >
                {isSecondary ? (
                  <>
                    <option value={PlatformFeeType.investors_pro_rata}>
                      {formatOdinPlatformFeePayee(
                        PlatformFeeType.investors_pro_rata
                      )}
                    </option>

                    <option value={PlatformFeeType.syndicate_leads}>
                      {formatOdinPlatformFeePayee(
                        PlatformFeeType.syndicate_leads
                      )}
                    </option>

                    <option value={PlatformFeeType.seller}>
                      {formatOdinPlatformFeePayee(PlatformFeeType.seller)}
                    </option>
                  </>
                ) : (
                  <>
                    <option value={PlatformFeeType.investors_pro_rata}>
                      {formatOdinPlatformFeePayee(
                        PlatformFeeType.investors_pro_rata
                      )}
                    </option>

                    <option value={PlatformFeeType.syndicate_leads}>
                      {formatOdinPlatformFeePayee(
                        PlatformFeeType.syndicate_leads
                      )}
                    </option>

                    <option value={PlatformFeeType.investee_company}>
                      {formatOdinPlatformFeePayee(
                        PlatformFeeType.investee_company
                      )}
                    </option>
                  </>
                )}
              </Form.Select>
            );
          }}
        />
        <StepSubmit isLoading={isSubmitting} />
      </VStack>
    </Form>
  );
}
