/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { fetcher } from "../../client/client";
import type {
  AccountForgotPasswordBody,
  AccountGetAppropriatenessAssessmentResponse,
  AccountGetSuccessResponse,
  AccountLoginBody,
  AccountLoginSuccessResponse,
  AccountResetPasswordBody,
  AccountSignupBody,
  AccountSignupSuccessResponse,
  AccountSubmitAppropriatenessAssessmentBodyBody,
  AccountSubmitAppropriatenessAssessmentResponse,
  AccountUpdateBody,
  AccountsClientCategorisationResultSubmitBody,
  AccountsGetImageSuccessResponse,
  AccountsImpersonateParams,
  AccountsSophisticatedInvestorDeclarationBodyBody,
  AccountsUploadImageBody,
  AuthOAuthProviderCallbackParams,
  AuthProviderListParams,
  AuthProviderListResponse,
  AuthSuccessResponse,
  BadRequestResponse,
  ConflictResponse,
  DirectAccessErrorResponse,
  InternalServerErrorResponse,
  NotAuthorisedResponse,
  NotFoundResponse,
} from "../schemas";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Start the forgot password process for an unauthenticated requester. This
triggers an email sent to the email address in the request body and then
when the user clicks the link in the email they are redirected to a page
where they can type a new password. This then uses reset-password below.

 */
export const accountsForgotPassword = (
  accountForgotPasswordBody: AccountForgotPasswordBody,
  signal?: AbortSignal
) => {
  return fetcher<void>({
    url: `/v3/accounts/forgot-password`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: accountForgotPasswordBody,
    signal,
  });
};

export const getAccountsForgotPasswordMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsForgotPassword>>,
    TError,
    { data: AccountForgotPasswordBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsForgotPassword>>,
  TError,
  { data: AccountForgotPasswordBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsForgotPassword>>,
    { data: AccountForgotPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountsForgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountsForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountsForgotPassword>>
>;
export type AccountsForgotPasswordMutationBody = AccountForgotPasswordBody;
export type AccountsForgotPasswordMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountsForgotPassword = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsForgotPassword>>,
    TError,
    { data: AccountForgotPasswordBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsForgotPassword>>,
  TError,
  { data: AccountForgotPasswordBody },
  TContext
> => {
  const mutationOptions = getAccountsForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Login as another user.
 */
export const accountsImpersonate = (
  params: AccountsImpersonateParams,
  signal?: AbortSignal
) => {
  return fetcher<AuthSuccessResponse>({
    url: `/v3/accounts/impersonate`,
    method: "GET",
    params,
    signal,
  });
};

export const getAccountsImpersonateQueryKey = (
  params: AccountsImpersonateParams
) => {
  return [`/v3/accounts/impersonate`, ...(params ? [params] : [])] as const;
};

export const getAccountsImpersonateQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsImpersonate>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  params: AccountsImpersonateParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsImpersonate>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAccountsImpersonateQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountsImpersonate>>
  > = ({ signal }) => accountsImpersonate(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsImpersonate>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AccountsImpersonateQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountsImpersonate>>
>;
export type AccountsImpersonateQueryError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useAccountsImpersonate<
  TData = Awaited<ReturnType<typeof accountsImpersonate>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  params: AccountsImpersonateParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsImpersonate>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountsImpersonate>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAccountsImpersonate<
  TData = Awaited<ReturnType<typeof accountsImpersonate>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  params: AccountsImpersonateParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsImpersonate>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountsImpersonate>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAccountsImpersonate<
  TData = Awaited<ReturnType<typeof accountsImpersonate>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  params: AccountsImpersonateParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsImpersonate>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAccountsImpersonate<
  TData = Awaited<ReturnType<typeof accountsImpersonate>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(
  params: AccountsImpersonateParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsImpersonate>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAccountsImpersonateQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Account login with email and password.
 */
export const accountsLogin = (
  accountLoginBody: AccountLoginBody,
  signal?: AbortSignal
) => {
  return fetcher<AccountLoginSuccessResponse>({
    url: `/v3/accounts/login`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: accountLoginBody,
    signal,
  });
};

export const getAccountsLoginMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsLogin>>,
    TError,
    { data: AccountLoginBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsLogin>>,
  TError,
  { data: AccountLoginBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsLogin>>,
    { data: AccountLoginBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountsLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountsLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountsLogin>>
>;
export type AccountsLoginMutationBody = AccountLoginBody;
export type AccountsLoginMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountsLogin = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsLogin>>,
    TError,
    { data: AccountLoginBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsLogin>>,
  TError,
  { data: AccountLoginBody },
  TContext
> => {
  const mutationOptions = getAccountsLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove cookies from requesting client.
 */
export const accountsLogout = (signal?: AbortSignal) => {
  return fetcher<void>({ url: `/v3/accounts/logout`, method: "GET", signal });
};

export const getAccountsLogoutQueryKey = () => {
  return [`/v3/accounts/logout`] as const;
};

export const getAccountsLogoutQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsLogout>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsLogout>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAccountsLogoutQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsLogout>>> = ({
    signal,
  }) => accountsLogout(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsLogout>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AccountsLogoutQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountsLogout>>
>;
export type AccountsLogoutQueryError =
  | BadRequestResponse
  | InternalServerErrorResponse;

export function useAccountsLogout<
  TData = Awaited<ReturnType<typeof accountsLogout>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsLogout>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof accountsLogout>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAccountsLogout<
  TData = Awaited<ReturnType<typeof accountsLogout>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsLogout>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof accountsLogout>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAccountsLogout<
  TData = Awaited<ReturnType<typeof accountsLogout>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsLogout>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAccountsLogout<
  TData = Awaited<ReturnType<typeof accountsLogout>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsLogout>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAccountsLogoutQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieve a list of available authentication providers.
 */
export const authProviderList = (
  params?: AuthProviderListParams,
  signal?: AbortSignal
) => {
  return fetcher<AuthProviderListResponse>({
    url: `/v3/accounts/oauth`,
    method: "GET",
    params,
    signal,
  });
};

export const getAuthProviderListQueryKey = (
  params?: AuthProviderListParams
) => {
  return [`/v3/accounts/oauth`, ...(params ? [params] : [])] as const;
};

export const getAuthProviderListQueryOptions = <
  TData = Awaited<ReturnType<typeof authProviderList>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(
  params?: AuthProviderListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authProviderList>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAuthProviderListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authProviderList>>
  > = ({ signal }) => authProviderList(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof authProviderList>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AuthProviderListQueryResult = NonNullable<
  Awaited<ReturnType<typeof authProviderList>>
>;
export type AuthProviderListQueryError =
  | BadRequestResponse
  | InternalServerErrorResponse;

export function useAuthProviderList<
  TData = Awaited<ReturnType<typeof authProviderList>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(
  params: undefined | AuthProviderListParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authProviderList>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authProviderList>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAuthProviderList<
  TData = Awaited<ReturnType<typeof authProviderList>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(
  params?: AuthProviderListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authProviderList>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authProviderList>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthProviderList<
  TData = Awaited<ReturnType<typeof authProviderList>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(
  params?: AuthProviderListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authProviderList>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAuthProviderList<
  TData = Awaited<ReturnType<typeof authProviderList>>,
  TError = BadRequestResponse | InternalServerErrorResponse,
>(
  params?: AuthProviderListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authProviderList>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAuthProviderListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Authenticate an account via an oauth2 provider. This will create an 
account if it does not already exist or sign in to an existing account.

 */
export const authOAuthProviderCallback = (
  oauthProvider: string,
  params: AuthOAuthProviderCallbackParams,
  signal?: AbortSignal
) => {
  return fetcher<DirectAccessErrorResponse>({
    url: `/v3/accounts/oauth/${oauthProvider}/callback`,
    method: "GET",
    params,
    signal,
  });
};

export const getAuthOAuthProviderCallbackQueryKey = (
  oauthProvider: string,
  params: AuthOAuthProviderCallbackParams
) => {
  return [
    `/v3/accounts/oauth/${oauthProvider}/callback`,
    ...(params ? [params] : []),
  ] as const;
};

export const getAuthOAuthProviderCallbackQueryOptions = <
  TData = Awaited<ReturnType<typeof authOAuthProviderCallback>>,
  TError =
    | AuthSuccessResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | DirectAccessErrorResponse,
>(
  oauthProvider: string,
  params: AuthOAuthProviderCallbackParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authOAuthProviderCallback>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAuthOAuthProviderCallbackQueryKey(oauthProvider, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof authOAuthProviderCallback>>
  > = ({ signal }) => authOAuthProviderCallback(oauthProvider, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!oauthProvider,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof authOAuthProviderCallback>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AuthOAuthProviderCallbackQueryResult = NonNullable<
  Awaited<ReturnType<typeof authOAuthProviderCallback>>
>;
export type AuthOAuthProviderCallbackQueryError =
  | AuthSuccessResponse
  | NotAuthorisedResponse
  | NotFoundResponse
  | DirectAccessErrorResponse;

export function useAuthOAuthProviderCallback<
  TData = Awaited<ReturnType<typeof authOAuthProviderCallback>>,
  TError =
    | AuthSuccessResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | DirectAccessErrorResponse,
>(
  oauthProvider: string,
  params: AuthOAuthProviderCallbackParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authOAuthProviderCallback>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOAuthProviderCallback>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAuthOAuthProviderCallback<
  TData = Awaited<ReturnType<typeof authOAuthProviderCallback>>,
  TError =
    | AuthSuccessResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | DirectAccessErrorResponse,
>(
  oauthProvider: string,
  params: AuthOAuthProviderCallbackParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authOAuthProviderCallback>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof authOAuthProviderCallback>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAuthOAuthProviderCallback<
  TData = Awaited<ReturnType<typeof authOAuthProviderCallback>>,
  TError =
    | AuthSuccessResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | DirectAccessErrorResponse,
>(
  oauthProvider: string,
  params: AuthOAuthProviderCallbackParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authOAuthProviderCallback>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAuthOAuthProviderCallback<
  TData = Awaited<ReturnType<typeof authOAuthProviderCallback>>,
  TError =
    | AuthSuccessResponse
    | NotAuthorisedResponse
    | NotFoundResponse
    | DirectAccessErrorResponse,
>(
  oauthProvider: string,
  params: AuthOAuthProviderCallbackParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof authOAuthProviderCallback>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAuthOAuthProviderCallbackQueryOptions(
    oauthProvider,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Reset the authenticated account's password.
 */
export const accountsResetPassword = (
  accountResetPasswordBody: AccountResetPasswordBody,
  signal?: AbortSignal
) => {
  return fetcher<void>({
    url: `/v3/accounts/reset-password`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: accountResetPasswordBody,
    signal,
  });
};

export const getAccountsResetPasswordMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsResetPassword>>,
    TError,
    { data: AccountResetPasswordBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsResetPassword>>,
  TError,
  { data: AccountResetPasswordBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsResetPassword>>,
    { data: AccountResetPasswordBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountsResetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountsResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountsResetPassword>>
>;
export type AccountsResetPasswordMutationBody = AccountResetPasswordBody;
export type AccountsResetPasswordMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountsResetPassword = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsResetPassword>>,
    TError,
    { data: AccountResetPasswordBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsResetPassword>>,
  TError,
  { data: AccountResetPasswordBody },
  TContext
> => {
  const mutationOptions = getAccountsResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Return the currently authenticated account data.
 */
export const accountsGetSelf = (signal?: AbortSignal) => {
  return fetcher<AccountGetSuccessResponse>({
    url: `/v3/accounts/self`,
    method: "GET",
    signal,
  });
};

export const getAccountsGetSelfQueryKey = () => {
  return [`/v3/accounts/self`] as const;
};

export const getAccountsGetSelfQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsGetSelf>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsGetSelf>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAccountsGetSelfQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsGetSelf>>> = ({
    signal,
  }) => accountsGetSelf(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsGetSelf>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AccountsGetSelfQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountsGetSelf>>
>;
export type AccountsGetSelfQueryError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useAccountsGetSelf<
  TData = Awaited<ReturnType<typeof accountsGetSelf>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsGetSelf>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof accountsGetSelf>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAccountsGetSelf<
  TData = Awaited<ReturnType<typeof accountsGetSelf>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsGetSelf>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof accountsGetSelf>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAccountsGetSelf<
  TData = Awaited<ReturnType<typeof accountsGetSelf>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsGetSelf>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAccountsGetSelf<
  TData = Awaited<ReturnType<typeof accountsGetSelf>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof accountsGetSelf>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAccountsGetSelfQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Update the authenticated account.
 */
export const accountsUpdateSelf = (accountUpdateBody: AccountUpdateBody) => {
  return fetcher<AccountGetSuccessResponse>({
    url: `/v3/accounts/self`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: accountUpdateBody,
  });
};

export const getAccountsUpdateSelfMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsUpdateSelf>>,
    TError,
    { data: AccountUpdateBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsUpdateSelf>>,
  TError,
  { data: AccountUpdateBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsUpdateSelf>>,
    { data: AccountUpdateBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountsUpdateSelf(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountsUpdateSelfMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountsUpdateSelf>>
>;
export type AccountsUpdateSelfMutationBody = AccountUpdateBody;
export type AccountsUpdateSelfMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountsUpdateSelf = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsUpdateSelf>>,
    TError,
    { data: AccountUpdateBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsUpdateSelf>>,
  TError,
  { data: AccountUpdateBody },
  TContext
> => {
  const mutationOptions = getAccountsUpdateSelfMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get the current status of an appropriateness assessment quiz.
 */
export const accountGetAppropriatenessAssessment = (signal?: AbortSignal) => {
  return fetcher<AccountGetAppropriatenessAssessmentResponse>({
    url: `/v3/accounts/self/appropriateness-assessment`,
    method: "GET",
    signal,
  });
};

export const getAccountGetAppropriatenessAssessmentQueryKey = () => {
  return [`/v3/accounts/self/appropriateness-assessment`] as const;
};

export const getAccountGetAppropriatenessAssessmentQueryOptions = <
  TData = Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAccountGetAppropriatenessAssessmentQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>
  > = ({ signal }) => accountGetAppropriatenessAssessment(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AccountGetAppropriatenessAssessmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>
>;
export type AccountGetAppropriatenessAssessmentQueryError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export function useAccountGetAppropriatenessAssessment<
  TData = Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAccountGetAppropriatenessAssessment<
  TData = Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
        TError,
        TData
      >,
      "initialData"
    >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAccountGetAppropriatenessAssessment<
  TData = Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAccountGetAppropriatenessAssessment<
  TData = Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof accountGetAppropriatenessAssessment>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getAccountGetAppropriatenessAssessmentQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Submit a set of answers for an appropriateness assessment quiz.
 */
export const accountSubmitAppropriatenessAssessment = (
  accountSubmitAppropriatenessAssessmentBodyBody: AccountSubmitAppropriatenessAssessmentBodyBody,
  signal?: AbortSignal
) => {
  return fetcher<AccountSubmitAppropriatenessAssessmentResponse>({
    url: `/v3/accounts/self/appropriateness-assessment`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: accountSubmitAppropriatenessAssessmentBodyBody,
    signal,
  });
};

export const getAccountSubmitAppropriatenessAssessmentMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountSubmitAppropriatenessAssessment>>,
    TError,
    { data: AccountSubmitAppropriatenessAssessmentBodyBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountSubmitAppropriatenessAssessment>>,
  TError,
  { data: AccountSubmitAppropriatenessAssessmentBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountSubmitAppropriatenessAssessment>>,
    { data: AccountSubmitAppropriatenessAssessmentBodyBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountSubmitAppropriatenessAssessment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountSubmitAppropriatenessAssessmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountSubmitAppropriatenessAssessment>>
>;
export type AccountSubmitAppropriatenessAssessmentMutationBody =
  AccountSubmitAppropriatenessAssessmentBodyBody;
export type AccountSubmitAppropriatenessAssessmentMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountSubmitAppropriatenessAssessment = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountSubmitAppropriatenessAssessment>>,
    TError,
    { data: AccountSubmitAppropriatenessAssessmentBodyBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountSubmitAppropriatenessAssessment>>,
  TError,
  { data: AccountSubmitAppropriatenessAssessmentBodyBody },
  TContext
> => {
  const mutationOptions =
    getAccountSubmitAppropriatenessAssessmentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Submission endpoint for the Client Categorisation quiz which we serve to
customers of our platform once every 12 months because of them FCA regs.

 */
export const accountsClientCategorisationResultSubmit = (
  accountsClientCategorisationResultSubmitBody: AccountsClientCategorisationResultSubmitBody,
  signal?: AbortSignal
) => {
  return fetcher<void>({
    url: `/v3/accounts/self/client-categorisation`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: accountsClientCategorisationResultSubmitBody,
    signal,
  });
};

export const getAccountsClientCategorisationResultSubmitMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsClientCategorisationResultSubmit>>,
    TError,
    { data: AccountsClientCategorisationResultSubmitBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsClientCategorisationResultSubmit>>,
  TError,
  { data: AccountsClientCategorisationResultSubmitBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsClientCategorisationResultSubmit>>,
    { data: AccountsClientCategorisationResultSubmitBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountsClientCategorisationResultSubmit(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountsClientCategorisationResultSubmitMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof accountsClientCategorisationResultSubmit>>
  >;
export type AccountsClientCategorisationResultSubmitMutationBody =
  AccountsClientCategorisationResultSubmitBody;
export type AccountsClientCategorisationResultSubmitMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountsClientCategorisationResultSubmit = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsClientCategorisationResultSubmit>>,
    TError,
    { data: AccountsClientCategorisationResultSubmitBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsClientCategorisationResultSubmit>>,
  TError,
  { data: AccountsClientCategorisationResultSubmitBody },
  TContext
> => {
  const mutationOptions =
    getAccountsClientCategorisationResultSubmitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Upload a profile image for the authenticated account.
 */
export const accountsUploadImage = (
  accountsUploadImageBody: AccountsUploadImageBody
) => {
  return fetcher<void>({
    url: `/v3/accounts/self/image`,
    method: "PUT",
    headers: { "Content-Type": "application/octet-stream" },
    data: accountsUploadImageBody,
  });
};

export const getAccountsUploadImageMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsUploadImage>>,
    TError,
    { data: AccountsUploadImageBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsUploadImage>>,
  TError,
  { data: AccountsUploadImageBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsUploadImage>>,
    { data: AccountsUploadImageBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountsUploadImage(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountsUploadImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountsUploadImage>>
>;
export type AccountsUploadImageMutationBody = AccountsUploadImageBody;
export type AccountsUploadImageMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountsUploadImage = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsUploadImage>>,
    TError,
    { data: AccountsUploadImageBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsUploadImage>>,
  TError,
  { data: AccountsUploadImageBody },
  TContext
> => {
  const mutationOptions = getAccountsUploadImageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Submit a sophisticated investor declaration. This is the final step in
the onboarding process for investors.

 */
export const accountsSophisticatedInvestorDeclaration = (
  accountsSophisticatedInvestorDeclarationBodyBody: AccountsSophisticatedInvestorDeclarationBodyBody,
  signal?: AbortSignal
) => {
  return fetcher<void>({
    url: `/v3/accounts/self/sophisticated_investor_declaration`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: accountsSophisticatedInvestorDeclarationBodyBody,
    signal,
  });
};

export const getAccountsSophisticatedInvestorDeclarationMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsSophisticatedInvestorDeclaration>>,
    TError,
    { data: AccountsSophisticatedInvestorDeclarationBodyBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsSophisticatedInvestorDeclaration>>,
  TError,
  { data: AccountsSophisticatedInvestorDeclarationBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsSophisticatedInvestorDeclaration>>,
    { data: AccountsSophisticatedInvestorDeclarationBodyBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountsSophisticatedInvestorDeclaration(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountsSophisticatedInvestorDeclarationMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof accountsSophisticatedInvestorDeclaration>>
  >;
export type AccountsSophisticatedInvestorDeclarationMutationBody =
  AccountsSophisticatedInvestorDeclarationBodyBody;
export type AccountsSophisticatedInvestorDeclarationMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountsSophisticatedInvestorDeclaration = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsSophisticatedInvestorDeclaration>>,
    TError,
    { data: AccountsSophisticatedInvestorDeclarationBodyBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsSophisticatedInvestorDeclaration>>,
  TError,
  { data: AccountsSophisticatedInvestorDeclarationBodyBody },
  TContext
> => {
  const mutationOptions =
    getAccountsSophisticatedInvestorDeclarationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Finish signing up with an already authenticated account.
 */
export const accountsSignup = (
  accountSignupBody: AccountSignupBody,
  signal?: AbortSignal
) => {
  return fetcher<AccountSignupSuccessResponse>({
    url: `/v3/accounts/signup`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: accountSignupBody,
    signal,
  });
};

export const getAccountsSignupMutationOptions = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsSignup>>,
    TError,
    { data: AccountSignupBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsSignup>>,
  TError,
  { data: AccountSignupBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsSignup>>,
    { data: AccountSignupBody }
  > = (props) => {
    const { data } = props ?? {};

    return accountsSignup(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AccountsSignupMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountsSignup>>
>;
export type AccountsSignupMutationBody = AccountSignupBody;
export type AccountsSignupMutationError =
  | NotAuthorisedResponse
  | NotFoundResponse
  | InternalServerErrorResponse;

export const useAccountsSignup = <
  TError =
    | NotAuthorisedResponse
    | NotFoundResponse
    | InternalServerErrorResponse,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsSignup>>,
    TError,
    { data: AccountSignupBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsSignup>>,
  TError,
  { data: AccountSignupBody },
  TContext
> => {
  const mutationOptions = getAccountsSignupMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns the 500x500 PNG profile image for the account.
 */
export const accountsGetImage = (accountId: string, signal?: AbortSignal) => {
  return fetcher<AccountsGetImageSuccessResponse>({
    url: `/v3/accounts/${accountId}/image`,
    method: "GET",
    signal,
  });
};

export const getAccountsGetImageQueryKey = (accountId: string) => {
  return [`/v3/accounts/${accountId}/image`] as const;
};

export const getAccountsGetImageQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsGetImage>>,
  TError = DirectAccessErrorResponse,
>(
  accountId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsGetImage>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAccountsGetImageQueryKey(accountId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountsGetImage>>
  > = ({ signal }) => accountsGetImage(accountId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!accountId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsGetImage>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AccountsGetImageQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountsGetImage>>
>;
export type AccountsGetImageQueryError = DirectAccessErrorResponse;

export function useAccountsGetImage<
  TData = Awaited<ReturnType<typeof accountsGetImage>>,
  TError = DirectAccessErrorResponse,
>(
  accountId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsGetImage>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountsGetImage>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAccountsGetImage<
  TData = Awaited<ReturnType<typeof accountsGetImage>>,
  TError = DirectAccessErrorResponse,
>(
  accountId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsGetImage>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountsGetImage>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAccountsGetImage<
  TData = Awaited<ReturnType<typeof accountsGetImage>>,
  TError = DirectAccessErrorResponse,
>(
  accountId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsGetImage>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAccountsGetImage<
  TData = Awaited<ReturnType<typeof accountsGetImage>>,
  TError = DirectAccessErrorResponse,
>(
  accountId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsGetImage>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAccountsGetImageQueryOptions(accountId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Check availability of given handle
 */
export const accountsHandleCheck = (handle: string, signal?: AbortSignal) => {
  return fetcher<void>({
    url: `/v3/profiles/${handle}/availability`,
    method: "GET",
    signal,
  });
};

export const getAccountsHandleCheckQueryKey = (handle: string) => {
  return [`/v3/profiles/${handle}/availability`] as const;
};

export const getAccountsHandleCheckQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  handle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsHandleCheck>>,
        TError,
        TData
      >
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAccountsHandleCheckQueryKey(handle);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof accountsHandleCheck>>
  > = ({ signal }) => accountsHandleCheck(handle, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!handle,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsHandleCheck>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AccountsHandleCheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountsHandleCheck>>
>;
export type AccountsHandleCheckQueryError =
  | BadRequestResponse
  | ConflictResponse
  | InternalServerErrorResponse;

export function useAccountsHandleCheck<
  TData = Awaited<ReturnType<typeof accountsHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  handle: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsHandleCheck>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountsHandleCheck>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAccountsHandleCheck<
  TData = Awaited<ReturnType<typeof accountsHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  handle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsHandleCheck>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof accountsHandleCheck>>,
          TError,
          TData
        >,
        "initialData"
      >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAccountsHandleCheck<
  TData = Awaited<ReturnType<typeof accountsHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  handle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsHandleCheck>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAccountsHandleCheck<
  TData = Awaited<ReturnType<typeof accountsHandleCheck>>,
  TError = BadRequestResponse | ConflictResponse | InternalServerErrorResponse,
>(
  handle: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof accountsHandleCheck>>,
        TError,
        TData
      >
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAccountsHandleCheckQueryOptions(handle, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
