import { css } from "@@panda/css";
import { Box, HStack, VStack } from "@@panda/jsx";
import { ResultOf } from "@internal/gql/client";
import { formatIsoDate } from "@internal/utils/dates";
import { createColumnHelper } from "@tanstack/react-table";
import { InformationTooltip } from "components/atoms/InformationTooltip";
import { StatusPill } from "components/atoms/StatusPill";
import { Typography } from "components/atoms/Typography";
import { formatInstrumentTypename, isDraft } from "screens/deals/utils";

import { CompanyCell } from "../CompanyCell/CompanyCell";
import { createdDealsQuery } from "./useCreatedDeals";

export enum Column {
  Company = "company",
  AmountReceived = "amount-received",
  Status = "status",
  Invested = "invested",
  TargetClose = "close",
}

export type CreatedDeal = ResultOf<
  typeof createdDealsQuery
>["createdDeals"][number];

const columnHelper = createColumnHelper<CreatedDeal>();

export const columns = [
  columnHelper.accessor("tradeName", {
    id: Column.Company,
    header: "Company",
    enableSorting: true,
    cell: (props) => (
      <CompanyCell
        name={props.row.original.tradeName}
        logo={props.row.original.logoUrl}
        type={formatInstrumentTypename(
          props.row.original.instrument.__typename
        )}
      />
    ),
  }),
  columnHelper.accessor(
    "investmentSummary.currentCalculations.totalReceived.amount",
    {
      id: Column.AmountReceived,
      header: () => (
        <HStack
          justifyContent={{ base: "flex-end", lg: "flex-start" }}
          alignItems="center"
        >
          Amount received{" "}
        </HStack>
      ),
      enableSorting: true,
      cell: (props) => {
        const isDealDraft = isDraft(props.row.original.status);
        return !isDealDraft ? (
          <VStack alignItems={{ base: "flex-end", lg: "flex-start" }} gap="xs">
            <Typography variant="body" lineHeight="tight">
              {props.row.original.investmentSummary?.currentCalculations
                ?.totalReceived.formatted ?? "0"}
            </Typography>
            <Typography variant="body" color="grey.gunsmoke" lineHeight="tight">
              / {props.row.original?.terms?.allocation?.formatted ?? "0"}
            </Typography>
          </VStack>
        ) : null;
      },
    }
  ),
  columnHelper.display({
    id: Column.Status,
    header: "Status",
    enableSorting: false,
    cell: (props) => (
      <Box
        w="full"
        display="flex"
        flexDir="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <StatusPill
          status={props.row.original.status}
          className={css({ w: "auto" })}
        />
      </Box>
    ),
  }),
  columnHelper.display({
    id: Column.Invested,
    enableSorting: false,
    header: () => (
      <HStack justifyContent="flex-end" alignItems="center">
        Invested{" "}
        <InformationTooltip
          content="Investors whose funds have been received, among all those invited to the deal."
          alt="Invested information"
        />
      </HStack>
    ),
    cell: (props) => {
      const isDealDraft = isDraft(props.row.original.status);
      return !isDealDraft ? (
        <VStack alignItems="flex-end" gap="xs">
          <Typography variant="body" lineHeight="tight">
            {props.row.original.investmentSummary?.investorsInvested ?? "0"}
          </Typography>
          <Typography variant="body" color="grey.gunsmoke" lineHeight="tight">
            / {props.row.original.investmentSummary?.investorsTotal ?? "0"}
          </Typography>
        </VStack>
      ) : null;
    },
  }),
  columnHelper.accessor("targetCloseDate", {
    id: Column.TargetClose,
    header: "Target close",
    enableSorting: true,
    cell: (props) => {
      const date = props.row.original.targetCloseDate;
      return (
        <Typography variant="body" color="grey.gunsmoke">
          {date ? formatIsoDate(date) : "–"}
        </Typography>
      );
    },
  }),
];
