import { HStack, VStack, styled } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { Typography } from "components/atoms/Typography";
import { ComponentPropsWithRef, ReactNode } from "react";

interface Props {
  icon: (svgProps: ComponentPropsWithRef<"svg">) => ReactNode;
  title: string;
  description?: string;
  selected?: boolean;
}

export function IconText({
  selected = false,
  title,
  description,
  icon: Icon,
}: Props) {
  return (
    <styled.div display="flex" flexDirection="row" alignItems="center">
      <HStack
        alignItems="center"
        justifyContent="center"
        bgColor={selected ? "brand.krilin" : "grey.dune"}
        transition="0.2s"
        borderRadius="100%"
        width="40px"
        height="40px"
        mr="m"
        flexShrink={0}
      >
        <Icon
          width="16px"
          height="16px"
          color={
            selected
              ? token("colors.grey.minecraft")
              : token("colors.brand.krilin")
          }
        />
      </HStack>
      <VStack alignItems="flex-start" gap="xs">
        <Typography
          variant="body"
          textAlign="start"
          color="white"
          lineHeight="tight"
        >
          {title}
        </Typography>

        {description ? (
          <Typography
            variant="body"
            textAlign="start"
            color="grey.gunsmoke"
            lineHeight="relaxed"
            fontSize={["xs", "xs", "sm"]}
          >
            {description}
          </Typography>
        ) : null}
      </VStack>
    </styled.div>
  );
}
