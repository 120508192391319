/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */
import { AccountStatusAssessmentRequired } from "./accountStatusAssessmentRequired";
import { AccountStatusCategorisationRequired } from "./accountStatusCategorisationRequired";
import { AccountStatusPasswordRequired } from "./accountStatusPasswordRequired";
import { AccountStatusPersonalDetailsRequired } from "./accountStatusPersonalDetailsRequired";
import { AccountStatusReady } from "./accountStatusReady";
import { AccountStatusSophisticatedInvestorDeclarationRequired } from "./accountStatusSophisticatedInvestorDeclarationRequired";

/**
 * An account status indicates whether or not an account is ready to invest
or requires further actions from the account holder before it's usable.
See the actual OpenAPI specification for a description of each value.

 */
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatus = {
  ...AccountStatusPasswordRequired,
  ...AccountStatusPersonalDetailsRequired,
  ...AccountStatusCategorisationRequired,
  ...AccountStatusAssessmentRequired,
  ...AccountStatusSophisticatedInvestorDeclarationRequired,
  ...AccountStatusReady,
} as const;
export type AccountStatus = (typeof AccountStatus)[keyof typeof AccountStatus];
