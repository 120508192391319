// We are using a custom copy icon in this case
import { styled } from "@@panda/jsx";
import { TextOverflow } from "components/atoms/TextOverflow";
import { ReactNode } from "react";

import { useCopyText } from "./useCopyText";

type Props = {
  text: string;
  label: string | ReactNode;
  toastLabel: string;
  className?: string;
  onCopied?: () => void;
  showIcon?: boolean;
};

export function CopyText(props: Props) {
  const {
    className,
    label,
    showIcon,
    text: _,
    toastLabel: __,
    ...rest
  } = props;
  const { onCopy } = useCopyText(props);

  return (
    <styled.button
      onClick={() => onCopy()}
      color={showIcon ? "white!" : "brand.krilin"}
      whiteSpace={showIcon ? "normal" : "nowrap"}
      textAlign={showIcon ? "right" : "center"}
      userSelect="auto"
      className={className}
      flexShrink="0"
      display="flex"
      alignItems="center"
      _hover={{
        textDecoration: "none",
        background: "transparent",
        color: "brand.yamcha",
      }}
      {...rest}
    >
      {typeof label === "string" ? (
        <TextOverflow
          width="full"
          maxW={{ base: "14rem", sm: "100%" }}
          as="span"
          mr="s"
          fontWeight="semibold"
          lineHeight="1.2"
        >
          {label}
        </TextOverflow>
      ) : (
        label
      )}
      {showIcon ? (
        <styled.svg
          flexShrink="0"
          width="1rem"
          height="1rem"
          viewBox="0 0 13 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.31055 15.1348C1.70508 15.1348 1.25 14.9805 0.945312 14.6719C0.644531 14.3672 0.494141 13.9082 0.494141 13.2949V5.15625C0.494141 4.54688 0.644531 4.08789 0.945312 3.7793C1.25 3.4707 1.70508 3.31641 2.31055 3.31641H4.4375V7.55859C4.4375 7.93359 4.52734 8.21289 4.70703 8.39648C4.88672 8.57617 5.16602 8.66602 5.54492 8.66602H9.63477V13.2949C9.63477 13.9043 9.48242 14.3633 9.17773 14.6719C8.87695 14.9805 8.42383 15.1348 7.81836 15.1348H2.31055ZM5.53906 7.86328C5.33594 7.86328 5.23438 7.76367 5.23438 7.56445V3.46289C5.34766 3.49414 5.46094 3.55273 5.57422 3.63867C5.69141 3.72461 5.81445 3.83398 5.94336 3.9668L9.07227 7.1543C9.20508 7.28711 9.30664 7.41016 9.37695 7.52344C9.44727 7.63672 9.49805 7.75 9.5293 7.86328H5.53906ZM10.4316 12.2109V8.82422C10.4316 8.42578 10.3828 8.05273 10.2852 7.70508C10.1914 7.35352 9.98047 7.01367 9.65234 6.68555L6.32422 3.36328C5.99609 3.03516 5.66016 2.8125 5.31641 2.69531C4.97266 2.57812 4.5957 2.51953 4.18555 2.51953H3.33594V2.18555C3.33594 1.59961 3.49023 1.1543 3.79883 0.849609C4.10742 0.544922 4.55859 0.392578 5.15234 0.392578H8.27539V3.85547C8.27539 4.16016 8.35742 4.39648 8.52148 4.56445C8.68945 4.72852 8.92773 4.81055 9.23633 4.81055H12.4766V10.3711C12.4766 10.6758 12.4375 10.9434 12.3594 11.1738C12.2852 11.4043 12.1719 11.5957 12.0195 11.748C11.8672 11.9004 11.6777 12.0156 11.4512 12.0938C11.2246 12.1719 10.9609 12.2109 10.6602 12.2109H10.4316ZM9.34766 4.07812C9.12891 4.07812 9.01953 3.96484 9.01953 3.73828L9.01367 0.632812C9.10352 0.644531 9.19141 0.681641 9.27734 0.744141C9.36719 0.806641 9.46289 0.890625 9.56445 0.996094L12.043 3.52148C12.1484 3.62695 12.2305 3.72266 12.2891 3.80859C12.3516 3.89453 12.3906 3.98438 12.4062 4.07812H9.34766Z"
            fill="#838383"
          />
        </styled.svg>
      ) : null}
    </styled.button>
  );
}
