import { CubeTransparentIcon } from "@heroicons/react/24/outline";
import { Link } from "components/atoms/Link";
import { useContext } from "react";
import { StepIntro } from "screens/onboarding/components/StepIntro";

import { ClientCategorizationContext } from "../../context";

export function CategorisationOverview() {
  const { setActiveStep } = useContext(ClientCategorizationContext);
  return (
    <StepIntro
      title="Investor Categorisation"
      icon={<CubeTransparentIcon width="2rem" color="white" />}
      desc={
        <>
          We are required to ask you to certify the category of Investor you are
          based on{" "}
          <Link
            isExternal
            href="https://www.legislation.gov.uk/en/uksi/2005/1529/schedule/5"
          >
            definitions the FCA provide.
          </Link>
          <br />
          <br />
          Your answers are private and will not be used for commercial purposes.
        </>
      }
      onContinue={() => setActiveStep("list")}
    />
  );
}
