import { Box } from "@@panda/jsx";
import { Link } from "components/atoms/Link";
import { Styled } from "components/styled";

import {
  SL_AGREEMENT_URL,
  SYNDICATE_TERMS_URL,
  USER_AGREEMENT_URL,
} from "./helpers";

export function TermsContent() {
  return (
    <Box maxHeight="40vh" overflowY="scroll">
      <Styled.p fontWeight="bold" pb="l">
        SYNDICATE LEAD AGREEMENT IN RELATION TO THE PLACEMENT OF INVESTMENT
        OPPORTUNITIES ON THE PLATFORM OF THE PLATFORM OPERATOR
      </Styled.p>
      <Styled.p pb="s">
        <Styled.span color="grey.gunsmoke">To:</Styled.span> Talbot Capital Ltd
        1st Floor, Nicholas House, 3 Laurence Pountney Hill, London, England,
        EC4R 0EU (the{" "}
        <Styled.strong fontWeight="bold">“Platform Operator”</Styled.strong>)
      </Styled.p>
      <Styled.p pt="m" pb="s">
        <Styled.span color="grey.gunsmoke">From:</Styled.span> The person(s)
        listed as ‘Syndicate Lead(s)’ in the relevant Deal Sheet (the{" "}
        <Styled.strong fontWeight="bold">“Syndicate Lead”</Styled.strong>)
      </Styled.p>
      <Styled.p pt="l" pb="s">
        Dear Sirs,
      </Styled.p>
      <Styled.ol
        display="flex"
        flexDir="column"
        gap="s"
        alignItems="flex-start"
        pl="l"
      >
        <Styled.li listStyle="decimal" data-testid="term-1">
          <Styled.p>
            I refer to the deal sheet linked on the relevant signature page on
            the Platform (the{" "}
            <Styled.strong fontWeight="bold">“Deal Sheet”</Styled.strong>).
            Terms not otherwise defined herein shall have the meaning provided
            in the{" "}
            <Link href={USER_AGREEMENT_URL} isExternal>
              Platform User Agreement
            </Link>
            .
          </Styled.p>
        </Styled.li>
        <Styled.li listStyle="decimal" data-testid="term-2">
          <Styled.p>
            I, being the Syndicate Lead as referred to in the Deal Sheet, hereby
            irrevocably and unconditionally request that the Platform Operator
            publish the Deal Sheet on the Deal Page on the Platform, in
            accordance with the{" "}
            <Link href={SYNDICATE_TERMS_URL} isExternal>
              Syndicate Terms
            </Link>{" "}
            for consideration by users of the Platform as to whether they shall
            agree to become Syndicate Members.
          </Styled.p>
        </Styled.li>
        <Styled.li listStyle="decimal" data-testid="term-3">
          <Styled.p>
            I hereby irrevocably and unconditionally acknowledge and agree that
            the publishing of the Deal Sheet on the Platform in accordance with
            paragraph 2 is at the sole discretion of the Platform Operator, and
            that the Platform Operator is under no obligation to publish the
            Deal Sheet or any Deal Documents (whether in accordance with the
            terms of this letter or otherwise).
          </Styled.p>
        </Styled.li>
        <Styled.li listStyle="decimal" data-testid="term-4">
          <Styled.p>
            No third party shall have any right to rely upon, enforce or
            otherwise invoke any term of this agreement pursuant to the
            Contracts (Rights of Third Parties) Act 1999.
          </Styled.p>
        </Styled.li>
        <Styled.li listStyle="decimal" data-testid="term-5">
          <Styled.p>
            The consideration under this agreement consists of the obligations
            of the parties to each other. The Platform Operator further agrees
            that payment by the Syndicate Lead to the Platform Operator of £1.00
            (receipt of which is acknowledged) amounts to good consideration in
            respect of the obligations of the Platform Operator under this
            agreement.
          </Styled.p>
        </Styled.li>
        <Styled.li listStyle="decimal" data-testid="term-6">
          <Styled.p>
            This agreement, and any non-contractual rights or obligations
            arising out of or in connection with it or its subject matter or
            formation shall be governed by and construed in accordance with the
            laws of England and Wales and each of the signatories hereby
            irrevocably submits to the exclusive jurisdiction of the courts of
            England and Wales in relation to any dispute arising in connection
            with the same.
          </Styled.p>
        </Styled.li>
      </Styled.ol>
      <Styled.p pt="xl" data-testid="term-witness">
        IN WITNESS whereof this Agreement has been entered into by the parties
        on the day that the Syndicate Lead indicates agreement to the{" "}
        <Link href={USER_AGREEMENT_URL} isExternal>
          Platform User Agreement
        </Link>
        ,{" "}
        <Link href={SYNDICATE_TERMS_URL} isExternal>
          Syndicate Terms
        </Link>{" "}
        and this{" "}
        <Link href={SL_AGREEMENT_URL} isExternal>
          Syndicate Lead Agreement
        </Link>{" "}
        on the Platform.
      </Styled.p>
      <Styled.p pt="xl">
        Signed by Greg Pritchard, for and on behalf of, Talbot Capital Ltd.
      </Styled.p>
    </Box>
  );
}
