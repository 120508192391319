import { Box } from "@@panda/jsx";
import { Link } from "components/atoms/Link";
import { Form } from "components/forms/Form/Form";

import { FormFields, FormLabels } from "../../utils";
import { useLegalNameField } from "./useLegalNameField";

const COMPANIES_HOUSE_NAME_CHECKER_URL =
  "https://find-and-update.company-information.service.gov.uk/";

export function LegalNameField() {
  const { ready } = useLegalNameField();

  return ready ? (
    <Box mt="l">
      <Form.TextField
        name={FormFields.EntityLegalName}
        placeholder="Enter legal name here"
        maxWidth="16rem"
        data-testid="legal-name-field"
        label={FormLabels.EntityLegalName}
        subLabel={
          <>
            This will be how your entity appears in any legal documentation. You
            can check names that are available{" "}
            <Link href={COMPANIES_HOUSE_NAME_CHECKER_URL} isExternal>
              here
            </Link>
            .
          </>
        }
        fieldOpts={{ required: "Please enter an entity legal name" }}
      />
    </Box>
  ) : null;
}
