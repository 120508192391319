import { HStack, VStack, styled } from "@@panda/jsx";
import { Typography } from "components/atoms/Typography";
import {
  LAYOUT_MAX_WIDTH,
  getLayoutProps,
} from "components/templates/Layout/utils";
import { LayoutWrap } from "components/templates/LayoutWrap";
import { ReactNode } from "react";
import Balancer from "react-wrap-balancer";
import { LogoMarquee } from "screens/syndicate/homepage/components/LogoMarquee/LogoMarquee";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

interface Props {
  title: string;
  subText: string;
  cta?: ReactNode;
}

export function EmptyDealsView({ title, subText, cta }: Props) {
  const { isLgScreenUp } = useBreakpoint("lgUp");

  return (
    <>
      <VStack
        mt={isLgScreenUp ? "6.875rem" : "l"}
        gap="0"
        {...getLayoutProps({
          width: LAYOUT_MAX_WIDTH,
        })}
      >
        <Balancer>
          <Typography
            variant="heading.1"
            as="h1"
            fontSize="xl"
            mb="xs"
            textAlign="center"
          >
            {title}
          </Typography>
        </Balancer>

        <Balancer>
          <styled.h2 fontSize="ld" m="0" fontWeight="light" color="grey.iron">
            {subText}
          </styled.h2>
        </Balancer>
      </VStack>

      <LogoMarquee />

      {cta ? (
        <LayoutWrap>
          <HStack justifyContent="center">{cta}</HStack>
        </LayoutWrap>
      ) : null}
    </>
  );
}
