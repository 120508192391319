import { css } from "@@panda/css";
import { VStack } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import {
  ArrowRightStartOnRectangleIcon,
  CogIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { TextOverflow } from "components/atoms/TextOverflow";
import { Thumbnail } from "components/atoms/Thumbnail";
import { Menu } from "components/molecules/Menu";

import { useAccountMenu } from "./useAccountMenu";

const OPTION_ICON_SIZE = "1.125rem";

const iconProps = {
  width: OPTION_ICON_SIZE,
  height: OPTION_ICON_SIZE,
  color: token("colors.grey.gunsmoke"),
};

export function AccountMenu() {
  const { handleLogout, data, avatar, isOpen, setIsOpen, isOnboarding } =
    useAccountMenu();

  return (
    <>
      <Menu variant="primary" onOpenChange={setIsOpen}>
        <Menu.Trigger
          data-testid="account-menu"
          aria-label="Account menu"
          className={css({ width: "1.9rem", height: "1.9rem" })}
        >
          <Thumbnail
            alt="User"
            src={avatar}
            isActive={isOpen}
            width="1.9rem"
            height="1.9rem"
            className={css({
              borderRadius: "lg",
              border: "none",
            })}
          />
        </Menu.Trigger>
        <Menu.Portal>
          <Menu.Content data-testid="account-menu">
            <VStack alignItems="flex-start" gap="xs" p="m">
              <TextOverflow
                width="12rem"
                variant="body"
                as="span"
                fontSize="md"
              >
                {data.name}
              </TextOverflow>

              <TextOverflow
                width="12rem"
                variant="subtext"
                as="span"
                color="grey.gunsmoke"
              >
                {data.email}
              </TextOverflow>
            </VStack>

            <Menu.Separator />

            {!isOnboarding ? (
              <Menu.Item
                variant="link"
                href={`/u/${data.handle}`}
                label="View profile"
                icon={<UserIcon {...iconProps} />}
              />
            ) : null}

            <Menu.Item
              variant="link"
              href="/settings"
              label="Account settings"
              icon={<CogIcon {...iconProps} />}
            />

            <Menu.Item
              variant="button"
              label="Logout"
              icon={<ArrowRightStartOnRectangleIcon {...iconProps} />}
              onClick={handleLogout}
            />
          </Menu.Content>
        </Menu.Portal>
      </Menu>
    </>
  );
}
