import {
  DraftMaterials,
  DraftUpdateBody,
} from "@internal/rest/generated/schemas";
import { GetFormLabels } from "@internal/utils/types";
import { urlNormalize } from "url-normalize";
import { createNameAndLabels } from "utility/forms";
import { isOdinHostedUrl } from "utility/url";
import { z } from "zod";

export const errorMsg = {
  investment_agreement: "Please provide a file or link",
};

export const MemoMaterialsSchema = z.object({
  investment_agreement: z
    .string()
    .refine(
      (v: string) => {
        if (isOdinHostedUrl(v)) return true;
        return Boolean(urlNormalize(v));
      },
      {
        message: "Please provide a valid link or file",
      }
    )
    .transform((v: string) => {
      if (isOdinHostedUrl(v)) return v;
      return urlNormalize(v, { forceProtocol: "https", www: true }) ?? "";
    }),
  pitch_deck: z
    .string()
    .refine(
      (v: string) => {
        if (isOdinHostedUrl(v)) return true;
        return Boolean(urlNormalize(v));
      },
      {
        message: "Please provide a valid link or file",
      }
    )
    .transform((v: string) => {
      if (isOdinHostedUrl(v)) return v;
      return urlNormalize(v, { forceProtocol: "https", www: true }) ?? "";
    }),
  pitch_deck_embed: z
    .string()
    .refine(
      (v: string) => {
        if (!v) return true;
        if (isOdinHostedUrl(v)) return true;
        return Boolean(urlNormalize(v));
      },
      {
        message: "Please provide a valid link",
      }
    )
    .transform((v: string) => {
      if (isOdinHostedUrl(v)) return v;
      return urlNormalize(v, { forceProtocol: "https", www: true }) ?? "";
    }),
});

export type FormValues = z.infer<typeof MemoMaterialsSchema>;
export type FormLabel = GetFormLabels<typeof getNameAndLabel>;

export const { getNameAndLabel } = createNameAndLabels(MemoMaterialsSchema, {
  investment_agreement: "Public link*",
  pitch_deck: "Public link*",
  pitch_deck_embed: "Embedded deck URL",
});

export const DEFAULT_TEMPLATE = `
<h3>Overview</h3>

<ul>
  <li><strong>Company:</strong> Established in mid-2019, company X makes widget Y for User Z.</li>
  <li><strong>Compelling founders?</strong> Rapid growth? Notable co-investors? Round already partly closed?</li>
  <li><strong>Clever business model?</strong> Strong gross margins? Evidence of network effects?</li>
  <li><strong>Incredible team?</strong> Interesting intellectual property? etc...</li>
</ul>

<h3>📗 Materials</h3>

<ul>
  <li>Links to pitch deck, financial model, demo  video</li>
  <li>Links or text explaining the product in simple term</li>
  <li>Current and previous investor</li>
</ul>

<h3> 🔎 Analysis </h3>

<strong>Market</strong> 

<p>Provide an overview of the market the company is targeting. What's the TAM, SAM and SOM? How fast is the market growing? If it's not fast growing, is there another reason the company is well-positioned to win? Is it a big future potential market?</p>

<strong>Team</strong>

<p>Talk about the founders, explain exactly what you like about them. Ensure you include links to their social media.</p>

<strong>Business model</strong>

<p>Briefly outline the company's business model and explain whether or not you think it is scalable (hopefully it is!).</p>

<strong>Traction & milestones</strong>

<p>What has the company achieved so far? Provide evidence that supports your proposal to invest in the business. Have they achieved product/market fit? If not, do you feel they're close?</p>

<strong>Financial</strong>

<p>Market timing is almost as important as having an interesting proposition or a strong team. Why is now the right time for a business like this. Revenue, MoM growth, unit economics, profitability, etc... Make sure you include the burn rate.</p>

<strong>Competition</strong>

<p>Who are the key competitors in this market? What evidence do you have that the company is in a strong position to beat them?</p>

<strong>Risks</strong>

<p>Name the top 3 or 4 risks you see with the business, and explain your thinking on how they can be mitigated.</p>

<strong>Use of funds</strong>

How will the funds being raised be deployed?

<h3> 📽 Webinar Details / Recordings </h3>

<p>Dial in details here for founder webinars. Gives people the opportunity to ask the founder questions, etc... For Odin, we typically run two per round, record one and share it afterwards with the community.</p>

<h3> 💬 FAQ's </h3>

<strong> Question 1 </strong>

<p>Answer 1</p>

<strong> Question 2 </strong>

<p>Answer 2</p>
`;

export const buildMaterialVariables = ({
  values,
  memo,
}: {
  values: DraftMaterials;
  memo: string;
}) => {
  const hasUploadedInvArgmt = isOdinHostedUrl(
    values.investment_agreement || ""
  );
  const hasUploadedPitchDeck = isOdinHostedUrl(values.pitch_deck || "");

  const buildVariables = (): NonNullable<DraftUpdateBody["materials"]> => {
    if (hasUploadedInvArgmt && hasUploadedPitchDeck) {
      return {
        memo_html: memo || "",
      };
    }

    if (hasUploadedInvArgmt) {
      return {
        memo_html: memo || "",
        pitch_deck: values.pitch_deck || "",
        pitch_deck_embed: values.pitch_deck_embed || "",
      };
    }

    if (hasUploadedPitchDeck) {
      return {
        memo_html: memo || "",
        investment_agreement: values.investment_agreement || "",
      };
    }

    return {
      memo_html: memo || "",
      investment_agreement: values.investment_agreement || "",
      pitch_deck: values.pitch_deck || "",
      pitch_deck_embed: values.pitch_deck_embed || "",
    };
  };

  return buildVariables();
};
