/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * From where the portfolio item was originated
 */
export type PublicProfilePortfolioItemOrigin =
  (typeof PublicProfilePortfolioItemOrigin)[keyof typeof PublicProfilePortfolioItemOrigin];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublicProfilePortfolioItemOrigin = {
  "on-platform": "on-platform",
  "off-platform": "off-platform",
} as const;
