import { Box } from "@@panda/jsx";
import { H } from "@highlight-run/next/client";
import {
  accountsImpersonate,
  accountsLogout,
  useAccountsGetSelf,
} from "@internal/rest/generated/queries/accounts";
import { APIError, Account } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingBanner } from "components/molecules/LoadingBanner/LoadingBanner";
import { useRouter } from "next/router";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { computeNeedsOnboarding } from "screens/onboarding/utils";
import { analytics } from "services/analytics";
import { init as initFeatureFlags } from "services/feature-flags";
import { toast } from "services/toast";

import { isPrivatePage, isPublicPage } from "./utils";

const AuthContext = createContext<AuthenticationContext>({
  status: "loading",
  error: undefined,
  account: undefined,
});

export function useAuth() {
  const state = useContext(AuthContext);

  if (!state) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return state;
}

export function AuthProvider({
  children,
  skipOnLaunchChecks = false,
}: PropsWithChildren<{ skipOnLaunchChecks?: boolean }>) {
  const state = useAuthProvider(skipOnLaunchChecks);

  if (skipOnLaunchChecks) {
    return (
      <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={state}>
      {state.status === "loading" ? (
        <Box position="relative" minH="100vh">
          <LoadingBanner cover={true} />
        </Box>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

type AuthenticationContext = {
  status: "loading" | "authenticated" | "unauthenticated";
  error: APIError | null | undefined;
  account: Account | undefined;
};

function useAuthProvider(skipOnLaunchChecks: boolean): AuthenticationContext {
  const router = useRouter();
  const queryClient = useQueryClient();

  const {
    data: account,
    error,
    isFetched: hasAttemptedAccountFetch,
  } = useAccountsGetSelf({
    query: {
      refetchOnWindowFocus: false,
    },
  });

  const isAuthenticated = !!account;

  const authTokenParam = router.query["impersonate"] as string | undefined;
  const isInviteLink = router.pathname.includes("invite");

  const needsOnboarding = computeNeedsOnboarding(account);
  const [hasDoneRedirects, setHasDoneRedirects] = useState(false);

  useEffect(() => {
    if (account?.id) {
      analytics.register(account);
    }
  }, [account?.id]);

  useEffect(() => {
    const onAppLaunch = async () => {
      if (account) await initFeatureFlags(account);

      // impersonation: token in URL param or local storage (legacy)
      if (authTokenParam) {
        try {
          // always try logging out but ignore fetch throwing
          try {
            await accountsLogout();
          } catch {
            //
          }

          await accountsImpersonate({
            impersonate: authTokenParam,
          });

          await router.replace({
            pathname: router.pathname,
            query: {
              ...router.query,
              impersonate: undefined,
            },
          });

          // for a refetch of the account via page reload in case the user needs to onboard
          router.reload();
          return;
        } catch (e) {
          await router.replace("/login");
          toast.show({
            status: "error",
            title: "Impersonation error",
            desc: `An error occurred when impersonating an account: ${JSON.stringify(
              e
            )}`,
          });
          return;
        }
      }

      // if the user isn't authenticated and page is private:
      if (!isAuthenticated && isPrivatePage(router.pathname)) {
        const params = new URLSearchParams({
          returnUrl: window.location.pathname + window.location.search,
        });

        await router.push(`/login?${params.toString()}`);
        setHasDoneRedirects(true);
        return;
      }

      if (isAuthenticated) {
        H.identify(account.email, { id: account.id, avatar: account.logo_url });
      }

      // if the user is authenticated and page is public:
      if (isAuthenticated && isPublicPage(router.pathname)) {
        await router.push("/");
        setHasDoneRedirects(true);
        return;
      }

      // if the user is authenticated but needs onboarding, redirect to onboarding
      if (isAuthenticated && needsOnboarding && !isInviteLink) {
        await router.replace("/onboarding");
        setHasDoneRedirects(true);
        return;
      }

      setHasDoneRedirects(true);
    };

    if (skipOnLaunchChecks || hasDoneRedirects || !hasAttemptedAccountFetch)
      return;
    void onAppLaunch();
  }, [
    hasAttemptedAccountFetch,
    isAuthenticated,
    hasDoneRedirects,
    authTokenParam,
    router,
    isInviteLink,
    queryClient,
    needsOnboarding,
  ]);

  const calculateStatus = () => {
    if (!hasDoneRedirects || !router.isReady) return "loading";
    if (isAuthenticated) return "authenticated";
    return "unauthenticated";
  };

  return {
    status: calculateStatus(),
    error,
    account,
  };
}
