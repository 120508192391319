/* eslint-disable react/jsx-key */
import { css } from "@@panda/css";
import { Box, Stack, styled } from "@@panda/jsx";
import { Button } from "components/atoms/Button";
import { CreateDealModal } from "components/molecules/CreateDealModal";
import { StatusBanner } from "components/molecules/StatusBanner";
import { Variants } from "components/molecules/Tabs/styles";

import { CreatedDeals } from "./components/CreatedDeals/CreatedDeals";
import { InvitedDeals } from "./components/InvitedDeals/InvitedDeals";
import { PassedDeals } from "./components/PassedDeals/PassedDeals";
import { PortfolioDeals } from "./components/PortfolioDeals/PortfolioDeals";
import { TabValue, dealTabs, useDealsScreen } from "./useDealsScreen";

const { Tabs, TabList, Tab, Panels, Panel } = dealTabs;

export function DealsScreen() {
  const { isReady, defaultTab, error } = useDealsScreen();

  if (!isReady) return <StatusBanner error={error} cover />;

  return (
    <Box
      className={css({
        p: "l",
        marginLeft: {
          lgDown: "0",
          lg: "4rem",
        },
      })}
    >
      <>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          flexDir="row"
          mb="0.75rem"
        >
          <styled.h1 fontWeight="light" fontSize="2xl">
            Deals
          </styled.h1>

          <CreateDealModal
            triggerSlot={
              <Button data-testid="main-launch-deal">Launch a deal</Button>
            }
          ></CreateDealModal>
        </Stack>

        <Tabs defaultValue={defaultTab} variant={Variants.Plain}>
          <TabList className={css({ mb: "1.5rem" })}>
            <Tab value={TabValue.Created} data-testid="created-tab">
              Created
            </Tab>

            <Tab value={TabValue.Invited} data-testid="invited-tab">
              Invited
            </Tab>

            <Tab value={TabValue.Portfolio} data-testid="portfolio-tab">
              Portfolio
            </Tab>

            <Tab value={TabValue.Passed} data-testid="passed-tab">
              Passed
            </Tab>
          </TabList>
          <Panels>
            <Panel value={TabValue.Created}>
              <CreatedDeals />
            </Panel>

            <Panel value={TabValue.Invited}>
              <InvitedDeals />
            </Panel>

            <Panel value={TabValue.Portfolio}>
              <PortfolioDeals />
            </Panel>

            <Panel value={TabValue.Passed}>
              <PassedDeals />
            </Panel>
          </Panels>
        </Tabs>
      </>
    </Box>
  );
}
