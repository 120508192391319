/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The payment reconciliation status.
 */
export type PaymentStatus = (typeof PaymentStatus)[keyof typeof PaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PaymentStatus = {
  NOT_INITIATED: "NOT_INITIATED",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
} as const;
