import { HighlightInit } from "@highlight-run/next/client";
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Analytics } from "@vercel/analytics/react";
import { type ComponentWithLayout, Layout } from "components/templates/Layout";
import { DefaultSeo } from "next-seo";
import { AppProps as NextAppProps } from "next/app";
import { useState } from "react";
import { Provider as ReactWrapBalancerProvider } from "react-wrap-balancer";
import { AuthProvider } from "services/auth/AuthProvider";
import { environment } from "services/environment";
import { NetworkConnectionProvider } from "services/network/Provider";
import { PingProvider } from "services/ping/PingProvider";
import { printSecretMessage } from "services/secret-message";
import { seo } from "services/seo";
import { ToastProvider } from "services/toast/provider";
import { isEmpty } from "utility/isEmpty";
import { queryClientConfig } from "utility/queryClientConfig";

import "../styles/index.css";
import "rc-slider/assets/index.css";
import "react-loading-skeleton/dist/skeleton.css";

import "../utility/polyfill/screen-orientation";

const env = environment.getEnvironment();
const isProduction = env.WEB_ADDRESS.includes("app.joinodin.com");

if (env.NODE_ENV === "production" && env.RUNTIME === "browser") {
  printSecretMessage();
}

interface AppProps extends NextAppProps {
  Component: ComponentWithLayout;
}

export default function App({ Component, pageProps, router }: AppProps) {
  const [queryClient] = useState(new QueryClient(queryClientConfig));
  const isPageSSR = isEmpty(pageProps) === false;

  return (
    <>
      <DefaultSeo {...seo.getTags()} />
      {isProduction ? (
        <>
          <HighlightInit
            projectId="1epwz0qg"
            serviceName="odin-frontend"
            environment={
              env.NODE_ENV === "development" ? "staging" : "production"
            }
            tracingOrigins
            networkRecording={{
              enabled: true,
              recordHeadersAndBody: true,
              urlBlocklist: [],
            }}
          />
          <Analytics />
        </>
      ) : null}
      <ToastProvider>
        <NetworkConnectionProvider>
          <PingProvider>
            <QueryClientProvider client={queryClient}>
              {env.NODE_ENV === "development" && (
                <ReactQueryDevtools initialIsOpen={false} position="bottom" />
              )}
              <HydrationBoundary state={pageProps.dehydratedState}>
                <AuthProvider skipOnLaunchChecks={isPageSSR}>
                  <ReactWrapBalancerProvider preferNative={false}>
                    <Layout variant={Component.variant}>
                      <Component pathName={router.pathname} {...pageProps} />
                    </Layout>
                  </ReactWrapBalancerProvider>
                </AuthProvider>
              </HydrationBoundary>
            </QueryClientProvider>
          </PingProvider>
        </NetworkConnectionProvider>
      </ToastProvider>
    </>
  );
}
