/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * A syndicate fee type, percentage based fees scale based on the total
allocation whereas fixed fees are just a flat price. Fixed fees are 
preferred most of the time as it encourages investors to commit more.

 */
export type SyndicateFeeType =
  (typeof SyndicateFeeType)[keyof typeof SyndicateFeeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SyndicateFeeType = {
  none: "none",
  fixed: "fixed",
  percent: "percent",
} as const;
