import { SyndicateListItem } from "@internal/rest/generated/schemas";
import { Skeleton } from "components/atoms/Skeleton";
import { Form } from "components/forms/Form/Form";

import {
  CREATE_NEW_SYNDICATE_OPTION,
  FormFields,
  FormLabels,
} from "../../utils";
import { useSelectSyndicateField } from "./useSelectSyndicateField";

interface Props {
  onSelectedSyndicate: (syndicate: SyndicateListItem) => void;
}

export function SelectSyndicateField(props: Props) {
  const { parsedSyndicates, isReady } = useSelectSyndicateField(props);

  return isReady ? (
    <Form.Select
      name={FormFields.Syndicate}
      label={FormLabels.Syndicate}
      invalidErrorMessage="Please select a syndicate"
      fieldOpts={{ required: true }}
      selectContainerStyle={{ maxWidth: "16rem" }}
      data-testid="select-syndicate-field"
    >
      {parsedSyndicates?.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
      <option value={CREATE_NEW_SYNDICATE_OPTION}>
        Create a new syndicate
      </option>
    </Form.Select>
  ) : (
    <Skeleton height="3.5rem" width="16rem" />
  );
}
