/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Only present if investment_type is "equity"
 */
export type DraftTermsEquityShareClass =
  (typeof DraftTermsEquityShareClass)[keyof typeof DraftTermsEquityShareClass];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DraftTermsEquityShareClass = {
  preferred: "preferred",
  common: "common",
} as const;
