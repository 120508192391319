import { HStack, VStack } from "@@panda/jsx";
import { Button } from "components/atoms/Button";
import { Typography } from "components/atoms/Typography";
import { StatusBanner } from "components/molecules/StatusBanner";
import Balancer from "react-wrap-balancer";
import { StepWrap } from "screens/onboarding/components/StepWrap";

import { FailedState } from "./components/FailedState";
import { RadioCard } from "./components/RadioCard";
import { useAssessmentQuestions } from "./useAssessmentQuestions";

export function AssessmentQuestions() {
  const {
    error,
    status,
    question,
    answers,
    attempts,
    selectedAnswer,
    handleSelectAnswer,
    goToNextQuestion,
    goToPrevQuestion,
    handleReset,
    isLoading,
    handleSubmitAssessment,
    isFirstQuestion,
    isLastQuestion,
  } = useAssessmentQuestions();

  if (error) return <StatusBanner cover error={error} />;

  if (status === "completed_failed") {
    return (
      <FailedState
        attempts={attempts}
        hasHardFailed={false}
        handleReset={handleReset}
      />
    );
  }

  if (status === "blocked") {
    return <FailedState hasHardFailed={true} />;
  }

  return (
    <StepWrap>
      <VStack gap="m">
        <Typography variant="heading.2" textAlign="center">
          Investor Assessment
        </Typography>

        <Balancer ratio={0.5}>
          <Typography variant="body" color="grey.gunsmoke" textAlign="center">
            Please answer these short questions to complete your account setup.
          </Typography>
        </Balancer>

        <VStack alignItems="flex-start" w="full" gap="s">
          <Typography
            variant="heading.4"
            textAlign="center"
            w="full"
            fontWeight="500!"
            lineHeight="1.8"
            mb="-s"
          >
            {question || ""}
          </Typography>

          <VStack py="xl" gap="1rem" alignItems="center" w="full">
            {answers?.map((answer) => (
              <RadioCard
                key={answer.id}
                onClick={() => handleSelectAnswer(answer.id)}
                isChecked={selectedAnswer === answer.id}
                option={answer.answer_text}
                value={answer.id}
                subText={answer.sub_text ?? ""}
              />
            ))}
          </VStack>

          <HStack width="full">
            <Button
              isDisabled={isFirstQuestion}
              variant="secondary"
              width="full"
              onClick={goToPrevQuestion}
            >
              Back
            </Button>

            <Button
              width="full"
              isLoading={isLoading}
              isDisabled={!selectedAnswer}
              onClick={
                isLastQuestion ? handleSubmitAssessment : goToNextQuestion
              }
            >
              {isLastQuestion ? "Submit" : "Continue"}
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </StepWrap>
  );
}
