import { Dialog } from "components/molecules/Dialog";

import { CreateDealOptions } from "./CreateDealOptions/CreateDealOptions";

type Props = {
  triggerSlot: React.ReactNode;
};

export function CreateDealModal({ triggerSlot }: Props) {
  return (
    <Dialog
      title=""
      styling={{
        maxWidth: "30rem",
      }}
    >
      <Dialog.Trigger>{triggerSlot}</Dialog.Trigger>
      <Dialog.Content>
        <CreateDealOptions />
      </Dialog.Content>
    </Dialog>
  );
}
