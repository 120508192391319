/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The kind of relationship a member can have with a syndicate.
 */
export type SyndicateMembershipType =
  (typeof SyndicateMembershipType)[keyof typeof SyndicateMembershipType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SyndicateMembershipType = {
  normal: "normal",
  lead: "lead",
} as const;
